*{
    --grey:#7C7C7C;
    --linea: #BABABA;
    --number: #6D6D6D;
}

.search-container{
    background-color: #fff !important;
    box-shadow: 0 0 3px rgb(46, 46, 46);
    min-width: 180px;
    max-width: 307px;
    padding-top: 10px;
    transition: .4s ease all;
}

.search{
    margin-left: 8px
}

.input-field input{
    border: none;
    border-radius: none;
    position: relative;
    color: var(--darkBlue-01);
    font-family: var(--circularBook);
    padding: 8px 15px;
}

input:focus-visible{
    border: none;
    outline: 0;
}

.input-field svg{
    transform: translate(-3px, -4px);
}
.input-field{
    padding: 0 15px;
    border-bottom: var(--grey) 1px solid;
}

.number{
    color: var(--number);
}


.search-container .list-container{
    max-height: 200px;
    overflow-y: scroll;
}

.search-container ul{
    padding: 0;
}


.search-container ul li{
    font-weight: bold;
    border-bottom: var(--linea) 1px solid;
    list-style: none;
    color: var(--darkBlue);
    padding: 13px 18px 13px 18px;
}

.search-container ul li:hover{
    background: #bababa41;
}