.articles__destacado {
    display: flex;
    max-width: 1230px;
    margin: auto;
    padding: 0 2%;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.destacado__contenedor {
    background: white;
    color: #00214b;
    border-radius: 10px;
    display: flex;
    padding: 50px 8%;
    justify-content: flex-end;
    align-items: stretch;
    margin-top: 50px;
    gap: 5%;
}

.destacado__contenedor__contenedorDeImagen {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.destacado__contenedorDeImagen__imagen {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.destacado__contenedor__lateral {
    display: flex;
    flex-direction: column;
}

.destacado__contenedor__informacion {
    display: flex;
    flex-direction: column;
}

.contenedor__informacion__encabezado {
    font-size: 1.2rem;
}

.destacado__contenedor__informacion__contenedorDeImagen {
    display: none;
}

.contenedor__informacion__titulo {
    font-size: 2.3rem;
    font-weight: bold;
    margin: 0;
}

.contenedor__informacion__entradilla {
    font-size: 1rem;
}

.destacado__contenedor__botones {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.contenedor__botones__link1 {
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    color: #00214b;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    height: 50px;
    font-size: 21px;
    font-weight: bold;
    width: 150px;
    border: #00214b 1px solid;
}

.contenedor__botones__link1:hover {
    background: #005081;
    color: #ffffff;
}


.contenedor__botones__link {
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-size: 21px;
    font-weight: bold;
    color: #00214b;
    background: #ffda3a;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    height: 50px;
    width: 150px;
}

.contenedor__botones__link:hover {
    background: #005081;
    color: #ffffff;
}


@media (max-width:768px) {
    .destacado__contenedor {
        padding: 40px 6%;
        margin-top: 40px;
    }

    .contenedor__informacion__encabezado {
        font-size: 0.8rem;
    }

    .contenedor__informacion__titulo {
        font-size: 1.4rem;
        line-height: 30px;
    }

    .contenedor__informacion__entradilla {
        font-size: 0.8rem;
        line-height: 16px;
        padding-top: 16px;
    }

    .contenedor__botones__link1 {
        font-size: 1rem;
        height: 40px;
        width: 120px;
    }

    .contenedor__botones__link {
        font-size: 1rem;
        height: 40px;
        width: 120px;
    }

}

@media (max-width:638px) {
    .destacado__contenedor__informacion__contenedorDeImagen {
        width: 100%;
        display: flex;
        height: 270px;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 10px;
    }

    .destacado__contenedor__informacion__contenedorDeImagen__imagen {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .destacado__contenedor__contenedorDeImagen {
        display: none;
    }

    .articles__destacado {
        width: 81%;
        min-width: 300px;
    }

    .destacado__contenedor__botones{
        justify-content: flex-end;
    }
}