.owl-carousel .owl-nav button span{
    font-size: 40px;
    position: relative;
    top: -12px;
}

.tabs{
    top: 50px;
    z-index: 4;
    overflow: auto;
    position: absolute;
}

.tab{
    color: white;
    /* font-size: 22px; */
    padding: 10px 40px;
    white-space: nowrap;
    border-radius: 50px;
    border: 2px solid transparent;
    transition: all .3s ease-in-out;
    font-family: var(--circularBold);
    background: none;
}

.tab.active{
    border: 2px solid var(--yellow) !important;
}

.text-sliders img{
    transform: translateY(7px);
}
.nav-slider::-webkit-scrollbar-thumb{
    display: none !important;
    width: 0px !important;
    background: transparent !important; 
}
.nav-slider::-webkit-scrollbar{
    display: none !important;
    width: 0px !important;
    background: transparent !important; 
}
.scrollT{
    overflow: hidden;
}
.bg-gradient {
    z-index: 10;
    background: linear-gradient(#030E28, #030E2859, #030E2800, #030E2859, #030E28);
  }

  .containerConversions::-webkit-scrollbar{
    display: none;
  }

  .containerConversions {
    max-height: 100vh;
      overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }

  .panel {
    scroll-snap-align: start;
    min-height: 100vh  !important;
  }

  .desarrollo {
    height: 100vh;
    background: 
      url(./../../assets/img/bg-header-desarrollo-web.jpg);
      background-size: cover;
  }
  .videoGradient{
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.54) 19.13%, rgba(0, 0, 0, 0.00) 89.96%);
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    z-index: 1;
  }
.z-2{
  z-index: 2;
}
  @media (min-width: 1000px) and (max-height: 750px) {
    .conversiones-header{
      padding: 150px 0;
      height: 100%;
    }
  }
  

  @media (max-width:1000px) {
    header.desarrollo {
        height: auto !important;
    }


    .desarrollo-web h3{
        padding-top: 0
    }

    .titleconv{
        padding-top: 15vh;
    }

    .m-mobile{
        padding-top: 15vh;
    }
  }