.article__multimedia__photo{
    width: 100%;
    height: 250px;
    overflow: hidden;
    border-radius: 15px;
    margin: 35px 0;
}
.article__multimedia__photo__imagen{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.article__multimedia__video {
    width: 100%;
    height: auto;
    min-height: 250px;
    overflow: hidden;
    border-radius: 15px;
    margin: 35px 0;
    transition: aspect-ratio ease-out 500ms
}

.article__multimedia__video__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}