header.proyectos {
  height: 100vh;
  background: url(../../../public/images/monito_solo.svg) left 30% bottom 5% no-repeat, url(../../../public/images/img-header-proyectos.svg) right bottom no-repeat,
    url(../../../public/images/fondo_computadora.svg) center bottom no-repeat;
  background-size: 20vh, 100vh, cover;
}


@media only screen and (max-width:1108px) and (min-width: 768px) {
  header.proyectos {
    background-size: 16vh, 72vh, cover;
  }
  header h3{
    padding-top: 23.4vh;
  }
}

@media only screen and (max-width: 768px) {
  header.proyectos {
    background: url(../../../public/images/img-header-proyectos.svg) center bottom no-repeat,
      url(../../../public/images/fondo_computadora.svg) center center no-repeat;
    text-shadow: 0 0 2px black;
  }

  header h3{
    padding-top: 23.4vh;
  }

}

@media only screen and (max-width: 578px) {
  header.proyectos {
    background: url(../../../public/images/img-header-proyectos.svg) center bottom no-repeat,
      url(../../../public/images/Fondo_mobile_selva.svg) center center no-repeat;
    background-size: 90vw, cover;
    height: 88vh;
  }

}