
.contenido{
    width: 500px;
    height: 300px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contenido svg{
    transform: scale(1.7);
}

#ceja1{
    animation: levantarceja .7s .5s   infinite  ;
}

#ceja2{
    animation: levantarceja .7s .5s  infinite;                                           
}


.animate_dot0{
    animation: dotanimation 1s  infinite alternate;
}
.animate_dot{
    animation: dotanimation 1s .2s infinite alternate;
}
.animate_dot2{
    animation: dotanimation 1s .4s infinite alternate;
}




@keyframes dotanimation {
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}


@keyframes levantarceja {
    100%{
        transform: translateY(-3px);
    }
}

#ojo1, #ojo2{
    animation: moverojo .7s .5s  infinite alternate;    
}

@keyframes moverojo {
    50%{
        transform: translate(-3px, 3px);
    }
    100%{
        transform: translate(-3px, 3px);
    }
}