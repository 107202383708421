.main-container {
    padding-bottom: 1rem;
    padding-inline: 25px;
    padding-top: 50px;
    color: var(--darkBlue);
    position: relative;
    z-index: 5656565656;

    .container-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;

        .proyecto-titulo {
            font-family: var(--circularBook);
            margin-bottom: 0;
            font-size: 26px;
            /* line-height: 1.5rem; */
        }

        .proyecto-subtitulo {
            font-size: 15px;
            display: none;
        }

        .link-arrow {
            border: 2px solid var(--darkBlue);
            border-radius: 25rem;
            padding: 12px 35px;
            display: grid;
            place-items: center;
            width: fit-content;
            transition: all .2s ease-in-out;

            &:hover {
                background-color: var(--darkBlue);
            }

            .svg-arrow {
                fill: var(--darkBlue);
                transition: all .2s ease-in-out;

            }

            &:hover>.svg-arrow {
                fill: #fff;
                transform: translateX(-.5rem);
            }
        }
    }

    .container-proyecto-data {

        display: flex;

        .data-left {
            padding-right: 2rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            flex: 3;


            .container-textos {
                display: none;
            }

            .item-desc,
            .item-name {
                color: #777777;
                font-size: 18px;
                margin-bottom: 0;
                list-style-type: initial;
            }

            ol li {
                list-style-type: decimal;
            }

            .item-name {
                font-weight: bold;

                img {
                    position: relative;
                    bottom: 5px;
                }
            }

            .item-name.desatio{
                font-family: var(--circularBold);
            }

            .desafio-container {
                padding-left: .8rem;
            }

            .sector>span {
                color: var(--darkBlue);
                font-size: 12px;
            }
        }


        .data-right {
            flex: 1;
            display: flex;
            flex-direction: column;

            .tags-container {
                margin-left: auto;
                width: min(100%, 300px);
                display: none;
                flex-direction: row-reverse;
                flex-wrap: wrap-reverse;
                gap: 14px;

            }

        }
    }

}

.tag {
    border: 2px solid #E1E1E1;
    border-radius: 25rem;
    padding: 7px 20px;
    color: #414141;
    width: fit-content;
    text-transform: capitalize;
}

.accordeon-icon {
    height: 2px;
    background-color: var(--darkBlue);
    width: 10px;
    position: relative;
    top: 2.4px;
}

.accordeon-icon::after {
    content: '';
    position: absolute;
    height: 10px;
    width: 2px;
    background: var(--darkBlue);
    top: -4.4px;
    left: 4.4px;
    transition: all ease 200ms;
}

.accordeon-icon.open::after {
    rotate: 90deg;
}



@media screen and (min-width: 450px) {
    .main-container {
        .container-header {
            .proyecto-titulo {
                font-size: 48px;
            }

            .proyecto-subtitulo{
                display: block;
            }
        }


        .container-proyecto-data {
            .data-left {
                .proyecto-titulo {
                    font-size: 48px;
                }
            }
        }
    }
}

@media screen and (min-width: 690px) {
    .main-container {
        padding-top: 170px;
        z-index: 0;
    }
}


@media screen and (min-width: 900px) {
    .main-container {
        padding-inline: 70px;
        padding-bottom: 5rem;

        .container-header {
            margin-bottom: 3rem;

        }


        .container-proyecto-data {
            .data-left {
                .sector>span {
                    color: #777777;
                    font-size: 18px;
                }

                .container-textos {
                    display: block;
                }
            }

            .data-right {
                .tags-container {
                    display: flex;

                }
            }
        }
    }
}


@media screen and (min-width: 900px) {

    .contenedor-acordeones {
        display: none;
    }
}