.container-footer-brand {
    background-color: var(--darkBlue);
    background-image: url('../../../assets/img/branding/footer-gradient.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3rem 1.5rem 10rem;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .header {
        text-align: center;
        font-family: var(--circularLight);
        font-size: 32px;
        margin-bottom: 2rem;

        span {
            font-family: var(--circularBlack);
            color: var(--yellow);

        }
    }

    .form-y-mapa {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 3rem;

        .contenedor-derecha {
            display: flex;
            flex-direction: column;
            gap: 3rem;

            .contenedor-lista {
                .titulo {
                    font-family: var(--circularBook);
                    font-style: 20px;
                    margin-bottom: 1rem;
                }

                .contenedor-links-sitios {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    font-style: 20px;
                    color: #fff;

                    a {
                        font-size: 20px;
                        color: #fff;
                    }

                    a.capitalize {
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    .contactanos-titulo {
        font-family: var(--circularBook);
        font-size: 30px;
    }

    .contenedor-inputs {
        .country-select {
            color: #fff;
            font-family: var(--circularLight);
            flex-direction: row;
            width: 100%;
            flex: 1;
            margin-bottom: 3rem;
            border-bottom: 1px solid #fff;
        }

        .contenedor-telefono {
            flex: 2;
        }

        .contenedor-textarea {

            .textarea {
                background-color: transparent;
                width: 100%;
                outline: transparent;
                border-color: transparent;
                border-bottom: 1px solid #fff;
                padding-inline: 0.5rem;
                height: 2rem;
                color: #fff;
            }

            .fakePlaceholder {
                position: absolute;
                bottom: 0;
                left: 0;
                top: 5px;
                z-index: 10;
                transition: all ease 500ms;
            }

            .textarea:focus+.fakePlaceholder,
            .textarea:not(:placeholder-shown)+.fakePlaceholder {
                transform: translate(-10px, -28px);
                scale: 0.8;
                color: #00C6F1;
                font-weight: 100;
                transition: all ease 500ms;
            }
        }
    }

}

.enviar {
    background-color: var(--yellow);
    border: none;
    width: 100%;
    border-radius: 25rem;
    padding-block: 18px;
    font-size: 18px;
    margin-top: 1.5rem;
}

@media screen and (min-width: 1200px) {

    .container-footer-brand {
        .header {
            font-size: 64px;
        }

        .contenedor-inputs {
            .country-select {
                margin-bottom: 3.5rem;
            }
        }

        .form-y-mapa {
            margin-top: 4rem;
            margin-bottom: 4rem;
            flex-direction: row;
            justify-content: center;
            gap: 14rem;

            .contactanos-titulo {
                font-family: var(--circularBlack);
                font-size: 48px;
            }

            .contenedor-izquierda {
                width: 380px;
            }

            .contenedor-derecha {
                flex-direction: row;
                height: fit-content;
                padding-top: 5rem;
            }
        }
    }
}