@media (max-width:800px) {
    .slick-dots{
        position: absolute;
          z-index: 10;
          bottom: 0;
          display: flex;
          /* margin: auto; */
          padding: 0;
          width: 100%;
          justify-content: center;
      }
      
}