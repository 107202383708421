.main-container-input {
    margin-bottom: 3rem;

    .inputChimpaContainer {
        position: relative;

        .inputIcon {
            position: absolute;
            bottom: 10px;
            height: 1rem;
            width: 1.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .inputChimpa {
            width: 100%;
            background-color: transparent;
            border-color: transparent;
            border-bottom-color: #fff;
            border-width: 1px;
            padding-left: 1.74rem;
            padding-bottom: 0.38rem;
            outline: none;
            transition: all ease 500ms;
            color: #fff;

            &:-webkit-autofill,
            &:-webkit-autofill:hover, 
            &:-webkit-autofill:focus {
              -webkit-text-fill-color: #fff;
              -webkit-box-shadow: 0 0 0px 40rem var(--lightBlue) inset;
            }
        }

        .inputChimpa:focus-visible {
            border-bottom-color: #fff;
            border-width: 1px;
            border-style: solid;
        }

        .fakePlaceholder {
            position: absolute;
            bottom: 0;
            left: 1.5rem;
            top: 5px;
            z-index: 10;
            transition: all ease 500ms;
        }

        .inputChimpa:focus+.fakePlaceholder,
        .inputChimpa:not(:placeholder-shown)+.fakePlaceholder {
            transform: translate(-10px, -28px);
            scale: 0.8;
            color: #00C6F1;
            font-weight: 100;
            transition: all ease 500ms;
        }
    }


}

@media (min-width: 1024px) {
    .main-container-input {
        margin-bottom: 3.5rem;
    }
}