.btn-power.active .bg-color{
    fill: #32BE2F;
}

.btn-power.active{
    border: 0px !important;
    user-select: none;
    pointer-events: none;
}

.campos svg path{
    fill: var(--darkBlue)
}

.campos{
    border-bottom: 1px solid var(--darkBlue) !important;
}
.searchComponent{
    bottom: calc(- 255px);
    z-index: 3;
    position: absolute;
    left: 0;
    font-size: 18px;
}
.pregunta{
    font-weight: bolder;
    padding: 15px 0;
    font-size: 18px;
    font-family: 'Circular Bold';
    margin-block-end: 0;
}
.flecha svg{
    transform: translateY(-3px);
}

.custom_consulta{
    font-family: 'Circular Book';
    font-weight: bold;
}

.country_char{
    padding-bottom: 8px;
}

.telefono span{
    position: absolute;
    z-index: 2;
    width: 16px;
    height: 8px;
    left: -4px;
    bottom: -4px;
    background: #E8F0FB;
}

.input-group svg{
    width: 18px;
    height: 18px;
    margin-right: 8px;
}


.checkbox_preguntas{
    color: var(--darkBlue);
}
.checkboxes{
    font-family: 'Circular Book';
}
.checkbox_preguntas label{
    display: flex !important;
}


.btn-send .sendText svg{
    transform: rotate(-45deg) translateY(-7px);
    width: 18px;
    height: 18px;
}

#contact_form img{
    width: 80%;
    max-width: 270px;
}
#contact_form h1{
    font-size: clamp(2rem, 7vw, 3rem);
    font-family: 'Circular Bold';
}

#contact_form p{
    font-size: 18px;
    max-width: 350px;
    text-align: center;
    font-family: 'Circular Book';
}

@media (max-width:800px) {
    .flecha{
        position: absolute;
    }
    .flecha svg{
        transform: translateY(-3px) translateX(6px);
        
        width: 16px !important;
        height: 16px !important;
    }
}