.articles__pagina {
    display: flex;
    flex-wrap: wrap;
    max-width: 1290px;
    margin: auto;
    padding-left: 2.1%;
}

.pagina__articulo {
    background: white;
    border-radius: 10px;
    color: #00214b;
    min-width: 300px;
    width: 31%;
    margin: 0px 8px 70px 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.articulo__linkDeImagen {
    width: 100%;
    display: flex;
    height: 250px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
}

.articulo__linkDeImagen__cortina{
    width: 100%;
    position: absolute;
    height: 100%;
    z-index: 2;
    transition: all 0.2s ease-in-out;
    box-shadow: inset 0 0 0px #005081;
}

.pagina__articulo:hover .articulo__linkDeImagen__cortina {
    box-shadow: inset 0 0 10px #ffda3a;

}


.articulo__linkDeImagen__imagen {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.articulo__informacion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
    width: -webkit-fill-available;
}

.articulo__informacion__fechaDePublicacion {
    align-self: flex-end;
    font-size: 0.8rem;
    padding: 10px 0;
    display: flex;
    gap: 5px;
    align-items: center;
}
.articulo__informacion__linkDeTitulo{
    color: #00214b;
}
.articulo__informacion__titulo {
    font-size: 1.9rem;
    font-weight: bold;
    line-height: 40px;
    padding: 10px 0 0 0;
}

.articulo__informacion__entradilla {
    font-size: 1rem;
    line-height: 20px;
    padding-top: 20px;
}

.articulo__link {
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-size: 1.3rem;
    color: #00214b;
    background: #ffda3a;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    margin: auto 20px 0 20px;
    height: 50px;
    font-weight: bold;
    width: 150px;
    align-self: flex-end;
}

.articulo__link:hover {
    background: #005081;
    color: #ffffff;
}


.articulo__link__texto {}

.articulo__link__icono {
    rotate: 135deg;
    font-size: 2.1rem;
}

@media (max-width:985px) {
    .pagina__articulo {
        margin: 0px 6px 45px 6px;
        width: 47%;
        padding: 13px;
    }

}

@media (max-width:768px) {

    .articles__pagina {
        padding-left: 2.2%;
    }

    .articulo__informacion__fechaDePublicacion {
        font-size: 0.7rem;
        padding: 7px 0;
    }

    .articulo__informacion__titulo {
        font-size: 1.4rem;
        line-height: 30px;
        padding: 6px 0 0 0;
    }

    .articulo__informacion__entradilla {
        font-size: 0.8rem;
        line-height: 16px;
        padding-top: 16px;
    }

    .articulo__link {
        font-size: 1rem;
        height: 40px;
        width: 120px;
    }

    .articulo__link__icono {
        font-size: 1.6rem;
    }

}



@media (max-width:655px) {
    .pagina__articulo {
        width: 76%;
        margin: 0px auto 45px auto;
    }

    .articles__pagina {
        padding-left: 0;
    }

}