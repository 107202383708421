.main-contaner-galeria {
    padding-bottom: 5rem;
    padding-top: 3rem;
    padding-inline: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;


    .container-imagen {
        width: 100%;
        overflow: hidden;
        transition: all 400ms ease-in-out;

        &:hover {
            scale: 0.97;
        }

        .galeria-imagen {
            transition: all 400ms ease-in-out;
            height: 542px;
            width: 100%;
            object-fit: cover;
            object-position: center;

        }

        &:hover>.galeria-imagen {
            scale: 1.09;
        }

    }

    .contenedor-img-doble {
        width: 100%;
        display: flex;
        flex-direction: column;

        .container-imagen {
            flex: 1;
        }

    }

}


@media screen and (min-width: 700px) {
    .main-contaner-galeria {
        gap: 2rem;

        .container-imagen {
            .galeria-imagen {
                transition: all 400ms ease-in-out;
                height: auto;
                width: 100%;
                object-fit: cover;
            }
        }

        .contenedor-img-doble {
            gap: 2rem;
            flex-direction: row;
        }
    }
}

@media screen and (min-width: 900px) {
    .main-contaner-galeria {
        padding-inline: 70px;

      
    }
}

 