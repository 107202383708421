.article__BannerEnlace__contenido {}

.article__BannerEnlace__contenido__titulo {
    max-width: 500px;
    margin: 50px 80px 0px 80px;
    line-height: 35px;
    font-size: 35px;
    font-weight: bold;
    color: white;
}

.article__BannerEnlace__contenido__texto {
    max-width: 430px;
    line-height: 25px;
    font-size: 18px;
    margin: 10px 80px 50px 80px;
    color: white;
}

.article__BannerEnlace__enlace {
    transition: all 0.2s ease-in-out;
    position: relative;
    display: flex;
    background-color: #FFDA3A;
    border-radius: 25px;
    align-items: center;
    width: 200px;
    justify-content: center;
    font-weight: bold;
    padding: 7px;
    margin: 50px 80px 50px 80px;
    height: 53px;
    font-size: 21px;
    color: #00214b;
}
.article__BannerEnlace__enlace:hover {
    background: #005081;
    color: #ffffff;
}

.article__bannerDescarga {
    background-color: #00A3DA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    margin: 35px 0;
    flex-wrap: wrap;
}

.article__bannerDescarga__campana {
    position: absolute;
    right: -105px;
    top: -70px;
    transform: rotate(13deg);
}

.article__bannerDescarga__titulo {
    max-width: 500px;
    margin: 50px 80px;
    line-height: 52px;
    font-size: 48px;
    font-weight: bold;
    color: #003b68;
}

.article__bannerDescarga__enlace {
    transition: all 0.2s ease-in-out;
    display: flex;
    border: white 2px solid;
    border-radius: 25px;
    align-items: center;
    width: 200px;
    justify-content: center;
    color: white;
    font-weight: 100;
    padding: 7px;
    gap: 5px;
    margin: 50px 80px;
}


.article__bannerDescarga__enlace:hover {
    background: #005081;
    color: #ffffff;
}

.article__BannerEnlace {
    background: linear-gradient(90deg, #00A3DA 0%, #00214B 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    margin: 35px 0;
    flex-wrap: wrap;
}

.article__BannerEnlace__avion {
    position: absolute;
    right: -65px;
    top: -60px;
}

@media (max-width:768px) {

    .article__BannerEnlace__contenido__titulo {
        margin: 20px 10px 0 30px;
        line-height: 28px;
        font-size: 30px;
    }

    .article__BannerEnlace__contenido__texto {
        line-height: 20px;
        font-size: 15px;
        margin: 10px 10px 10px 40px;
    }

    .article__BannerEnlace__enlace {
        font-size: 1rem;
        height: 40px;
        width: 150px;
        margin: 25px 20px 20px 20px;
    }

    .article__bannerDescarga__campana {
        right: -45px;
        top: -30px;
        width: 120px;
    }

    .article__bannerDescarga__titulo {
        margin: 20px 30px;
        line-height: 30px;
        font-size: 30px;
    }

    .article__bannerDescarga__enlace {
        margin: 0 20px 20px 40px;
        width: 150px;
    }

    .article__BannerEnlace__avion {
        width: 100px;
        right: -50px;
        top: -35px;
    }

}