
.article__comentarios {}

.article__comentarios__titulo {
    font-weight: 600;
    font-size: 30px;
    align-self: flex-start;
}

.article__comentarios__comment {
    display: flex;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 965px;
    border-radius: 12px;

}

.article__comentarios__comment__frame {
    width: 75px;
    height: 75px;
    overflow: hidden;
    margin: 20px 20px auto 20px;
    border-radius: 50%;

}

.article__comentarios__comment__frame__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.article__comentarios__comment__box {
    margin: 20px 50px 40px 0px;
}

.article__comentarios__comment__box__autor {
    font-weight: 600;
}

.article__comentarios__comment__box__content {
    max-width: 800px;
    min-width: 150px;

}

.article__comentarios__comment__box__date {
    font-size: 12px;
    color: #947659;
}
