.articles__paginacion {
    display: flex;
    justify-content: center;
    height: 150px;
    align-items: center;
}

.paginacion__boton {
    transition: all 0.2s ease-in-out;
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    border: none;
    border-radius: 25px;
    background-color: transparent;
    color: white;
    cursor: url(../../../assets/img/cursor-hover.svg), pointer !important;
}

.paginacion__boton:hover{
    background-color: transparent;
    background: #005081;
}

.paginacion__boton__seleccionado {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    border: none;
    border-radius: 25px;
    background-color: #ffda3a;
    color: #00214b;
    cursor: url(../../../assets/img/cursor-hover.svg), pointer !important;
}

.paginacion__flechaIzquierda {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    margin-right: 5px;
    border: none;
    border-radius: 25px;
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: url(../../../assets/img/cursor-hover.svg), pointer !important;
}

.paginacion__flechaDerecha {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    margin-left: 5px;
    border: none;
    border-radius: 25px;
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    cursor: url(../../../assets/img/cursor-hover.svg), pointer !important;
}

@media (max-width:768px) {

   .paginacion__botonpaginacion__flechaDerecha{
    width: 35px;
    height: 35px;
    font-size: 0.9rem;
   }


}