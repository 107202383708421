.pulse:hover, 
.pulse:focus {
  animation: pulse 1s;
  box-shadow: 0 0 0 2em rgba(#fff,0);
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 white; }
}
.tecImg path{
  transition: .4s ease-in-out fill;
}
.tecImg{
  transition: .4s ease-in-out transform;
}
.tecImg:hover{
  transform: scale(1.05);

}
.tecImg{
  position: relative;
}
.tecImg::before{
  bottom: -28px;
  font-size: 20px;
  letter-spacing: 1px;
  position: absolute;
  width:100%;
  text-align:center;
  opacity: 0;
  transition: .4s ease-in-out;
}

.tecImg:hover::before{
  opacity: 1;
}

.wordpress::before{
  content: 'Wordpress';
}



.bootstrap::before{
  content: 'Bootstrap';
}

.html5::before{
  content: 'HTML5';
}

.javascript::before{
  content: 'Javascript';
}

.laravel::before{
  content: 'Laravel';
}

.angular::before{
  content: 'Angular';
}
.react img:hover{
  animation: giroReact 10s linear infinite;
}
.react::before{
  content: 'React';
}

@keyframes giro {
  0%{
    transform: rotateY(0deg);
  }
  100%{
    transform: rotateY(360deg);
  }
}


@keyframes giroReact {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}