.article__fondo{
    background-color: var(--lightBlue);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    overflow: hidden;
}
.article__fondo__title{
    font-size: 3rem;
    position: relative;
    z-index: 2;
    max-width: 700px;
    text-align: center;
    margin: 0 auto;
    color: white;
    padding: 20px 0 40px 0;
}
.article__fondo__imagen1{
    width: 100vw;
    position: absolute;
    z-index: 0;
    bottom: 0;    
}
.article__fondo__imagen2{
    width: 100vw;
    position: absolute;
    z-index: 1;
    bottom: 0;    
}


.article_body {
    background-color: white;
    color: #00214b;
    padding-bottom: 70px;
    overflow-x: hidden;
}

.article_body h1 {
    font-size: 3.815rem;
    margin-top: 2rem;
}

.article_body h2 {
    font-size: 3.052rem;
    margin-top: 1.8rem;
}

.article_body h3 {
    font-size: 2.441rem;
    margin-top: 1.5rem;
}

.article_body h4 {
    font-size: 1.953rem;
    margin-top: 1.25rem;

}

.article_body h5 {
    font-size: 1.563rem;
    margin-top: 1rem;
}

.article_body h6 {
    font-size: 1.25rem;
    margin-top: 0.7rem;
}

.artticle_body p {
    font-size: 1rem;
}

.article_body_Container {
    font-size: 1.3rem;
}

.article_body_Container__backnav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 60px 0 ;
}

.article_body_Container__backnav__link {
    color: #00A3DA;
}

.article_body_Container__backnav_arrow{
    
}

.article_body_Container__title {
    letter-spacing: 6px;
}

.article_body blockquote {
    margin: 0 0 1rem;
    padding-left: 10px;
    border-left: 2px solid #00214b;
    font-style: italic;
}

.article__subscribeAdd {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.article__subscribeAdd__text {
    font-size: 48px;
    max-width: 776px;
    text-align: center;
    font-weight: 600;
    margin: 0;
}

.article__subscribeAdd__cohete {
    position: absolute;
    right: -64px;
    top: -17px;
    transform: rotate(15deg);
}

.article__subscribeAdd__enlace {
    transition: all 0.2s ease-in-out;
    position: relative;
    display: flex;
    background-color: #FFDA3A;
    border-radius: 25px;
    align-items: center;
    width: 200px;
    justify-content: center;
    font-weight: bold;
    padding: 7px;
    margin: 50px 80px 50px 80px;
    height: 53px;
    font-size: 21px;
    color: #00214b;
}


.article__subscribeAdd__enlace:hover {
    background: #005081;
    color: #ffffff;
}


.relatedPostTitle {
    font-size: 48px;
    align-self: flex-start;
}

button:disabled{
    opacity: 0.7;
    pointer-events: none;
}

.ArticlePage .article_body ul {
    list-style: disc;
}

.ArticlePage .article_body p,
.ArticlePage .article_body h1,
.ArticlePage .article_body h2,
.ArticlePage .article_body h3,
.ArticlePage .article_body h4,
.ArticlePage .article_body h5,
.ArticlePage .article_body h6 {
    min-height: 1.2rem;
}
.ArticlePage {
    background-color: white;
    color: #00214b;
}

@media (max-width:768px) {

    .article__fondo__title {
        font-size: 2rem !important;
    }

    .article_body h1 {
        font-size: 2rem !important;
        margin-top: 1.5rem;
    }

    .article_body h2 {
        font-size: 1.75rem !important;
        margin-top: 1.2rem;
    }

    .article_body h3 {
        font-size: 1.5rem !important;
        margin-top: 1rem;
    }

    .article_body h4 {
        font-size: 1.4rem !important;
        margin-top: 0.8rem;

    }

    .article_body h5 {
        font-size: 1.3rem !important;
        margin-top: 0.7rem;
    }

    .article_body h6 {
        font-size: 1.2rem !important;
        margin-top: 0.4rem;
    }

    .article_body_Container {
        font-size: 1rem;
    }

    .article_body_Container__backnav {
        font-size: 0.7rem;
    }
    .article_body_Container__title {
        letter-spacing: 4px;
        font-size: 1rem;
    }
    .article__subscribeAdd__text {
        font-size: 32px;
    }
    .article__subscribeAdd__cohete {
        width: 60px;
        right: -30px;
        top: 0px;
    }
    .article__subscribeAdd__enlace {
        font-size: 1rem;
        height: 40px;
        width: 120px;
    }
    .relatedPostTitle {
        font-size: 36px;
    }
}