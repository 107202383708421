.galery-section {
  margin: 40px 0px;
}

.yellow-separator {
  width: 20%;
  border: 1px solid var(--yellow);
}

.galery {
  padding-right: 10px;
}

.inner-box {
  position: relative;
}

.inner-box .image {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.inner-box .image .img-portfolio {
  position: relative;
  width: 100%;
  display: block;
  transition: all 0.3s;
  min-width: "50px";
  object-fit: cover;
  height: 100%;
}

.inner-box .image .overlay-box {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  top: 0px;
  z-index: 2;
  opacity: 0;
  display: block;
  transition: all 0.3s ease;
}

.inner-box .image .overlay-box:before {
  position: absolute;
  content: "";
  left: -450px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  transform: scale(1.1);
  transition: all 0.6s ease-in-out;
  background: linear-gradient(to top, #001631, transparent);  
  /* background: url(../../../../public/images/filtro_hover_galery_card.svg) center center; */
  background-size: cover;
  border-radius: 10px;
}

.inner-box:hover .image .img-portfolio {
  transform: scale(1.1);
  
}


.inner-box:hover .image .overlay-box:before {
  opacity: 1;
  left: 0px;
  
}

.inner-box:hover .image .overlay-box {
  opacity: 1;
}

.inner-box:hover .image .overlay-box .overlay-content h5 {
  transform: translateX(0px);
}

.inner-box:hover .image .overlay-box .overlay-content p {
  transform: translateX(0px);
}

.inner-box .image .overlay-box .overlay-inner {
  width: 100%;
  min-width: 100%;
  position: absolute;
  left: 0px;
  bottom: 10px;
  z-index: 1;
  padding: 10px 15px;
}

.inner-box .image .overlay-box .overlay-inner .overlay-content i {
  font-size: 24px;
}

.inner-box .image h3 {
  color: #fff;
}

.card-flag{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 33px;
  height: 25px;
 
} 

.card-title {
  font-size: 18px;
  margin-bottom: 0px;
  padding: 0px 8px;
  margin-top: 5px;
  color: #ffffff;
  transform: translateX(-150px);
  transition: all 0.5s;
  max-width: 220px;
}
.card-title-proyect {
  font-size: 18px;
  margin-bottom: 0px;
  padding: 0px 8px 10px 8px;
  margin-top: 2px;
  text-transform: capitalize;
  color: var(--lightBlue);
  transform: translateX(-150px);
  transition: all 0.5s;
}

.title-and-year{
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between; 
}

.card-year{
  padding: 5px;
  font-size: 18px;
  margin: 0;
  color: var(--lightBlue);
}


.portfolio-image {
  width: 50px;
  height: 50px;
  text-align: center;
  background: #fffffff1;
  color: var(--darkBlue);
  padding-top: 6px;
  position: absolute;
  top: 30%;
  font-size: 30px;
  margin-left: -28px;
  left: 50%;
}

.animate-width{
  transition: width 0.5s ease-in-out;
}

.order-by {
  margin-bottom: 18px;
  background-color: var(--lightBlue) !important;
  padding: 5px 40px;
  color: #fff !important;
  border-radius: 4px;
  border: 1px solid var(--lightBlue) !important;
  font-family: var(--circularLight);
  max-width: 270px;
  text-align: center !important;
  background-image: url(../../../../public/images/chevron-down-white.svg) !important
}


.order-by:hover {
  cursor: url(../../../../public/images/cursor.svg), pointer;
}

.see-more {
  margin-bottom: 18px;
  background-color: transparent;
  padding: 5px 30px;
  color: #fff;
  border-radius: 20px;
  border: 2px solid var(--lightBlue);
  font-family: var(--circularLight);
}

.see-more:hover {
  cursor: url(../../../../public/images/cursor.svg), pointer;
}
.see-more:active {
  background-color: var(--lightBlue);
}


.menu-options {
  background-color: var(--lightBlue) !important;
}

.custon-drop-item {
  color: #fff !important;
  margin: 2px;
}

.custon-drop-item:hover {
  background-color: var(--lightBlue-01) !important;
}


.no-visible{
  visibility: hidden;
}

.loader{
  margin-top: 20px;
  color: var(--lightBlue);
}

.shuffle-item.loaded {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.shuffle-item:not(.loaded) {
  opacity: 0;
}