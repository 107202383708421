.filter-cont {
  position: absolute;
  z-index: 90;
  margin-top: 5px;
}

.no-show {
  display: none;
  opacity: 0;
}

.filter-icon {
  font-size: 24px;
  position: relative;
  background-color: var(--lightBlue);
  padding: 5px 10px;
  border-radius: 2px;
  opacity: 1;
  transition: all 1s ease-out;
}

.inside-icon {
  font-size: 24px;
  padding: 5px 10px;
}

.dropdown-custom {
  top: -5px;
  left: 0;
  position: absolute;
  display: none;
  z-index: -1;
  background-color: var(--lightBlue);
  border-radius: 2px;
  opacity: 0;
  transition: all 0.2s ease-in;
}
.dropdown-custom::after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 7px);
  height: 30px;
  background: linear-gradient(to bottom, transparent,#36a8e1cb,#36a9e1);
}

.containerForm{
  height: 0;
  transition: height .4s ease;
  overflow: hidden;
}

.generalSize{
  width: 100%;
}
.mobileSize{
  width: 200px;
}


.filter-body::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.filter-body::-webkit-scrollbar-track {
  background: #f3f1f181;
  border-radius: 20px;
}

/* Handle */
.filter-body::-webkit-scrollbar-thumb {
  background: var(--lightBlue-02);
  border-radius: 20px;
}


.show {
  display: block;
  opacity: 1;
}

.filter-header {
  font-family: var(--circularBold);
  font-size: 18px;
  margin: 0;
}

.icon-and-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.close-icon {
  font-size: 18px;
  margin-right: 10px;
}

.line-separator {
  margin: 0px 0px 15px 0px;
  border: 1px solid #ffffff;
}

.filter-body {
  width: 100%;
  position: relative;
  overflow-y: scroll;
  height:  calc(80vh - 7.2rem);
  padding: 10px 20px 15px;
  overflow-x: hidden;
}

.bi-chevron-right{
  transform: rotate(90deg);
  transition: transform .4s ease;
}

.filter-by {
  font-family: var(--circularLight);
  font-size: 14px;
}

.inputs-cont {
  margin-top: 15px;
  width: 100%;
  position: relative;
}

.category-header {
  color: #ffffffed;
}

.custom-checkbox:checked {
  background-color: #ffffff;
  border-radius: 0.15em !important;
  border: none !important ;
  text-transform: capitalize;
}

.custom-checkbox:hover {
  cursor: url(../../../../public/images/cursor.svg), pointer;
}

.sticky-column {
  position: sticky !important;
  top: 120px !important;
  z-index: 5;
}

.heightFixed{
  height: calc(100vh - 7.2rem);
}

.no-load{
  display: none;
}