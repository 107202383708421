@charset "UTF-8";

.owl-carousel .owl-item {
  border: none !important;
}

* {
  -webkit-tap-highlight-color: transparent !important;
}

*:focus {
  -webkit-tap-highlight-color: transparent !important;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

@font-face {
  font-family: "Circular Light";
  src: url("../fonts/CircularSpotifyText-Light.eot");
  src: url("../fonts/CircularSpotifyText-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/CircularSpotifyText-Light.woff2") format("woff2"),
    url("../fonts/CircularSpotifyText-Light.woff") format("woff"),
    url("../fonts/CircularSpotifyText-Light.ttf") format("truetype"),
    url("../fonts/CircularSpotifyText-Light.svg#CircularSpotifyText-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular Book";
  src: url("../fonts/CircularStd-Book.eot");
  src: url("../fonts/CircularStd-Book.eot?#iefix") format("embedded-opentype"),
    url("../fonts/CircularStd-Book.woff2") format("woff2"),
    url("../fonts/CircularStd-Book.woff") format("woff"),
    url("../fonts/CircularStd-Book.ttf") format("truetype"),
    url("../fonts/CircularStd-Book.svg#CircularStd-Book") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular Bold";
  src: url("../fonts/CircularStd-Bold.eot");
  src: url("../fonts/CircularStd-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/CircularStd-Bold.woff2") format("woff2"),
    url("../fonts/CircularStd-Bold.woff") format("woff"),
    url("../fonts/CircularStd-Bold.ttf") format("truetype"),
    url("../fonts/CircularStd-Bold.svg#CircularStd-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular Black";
  src: url("../fonts/CircularStd-Black.eot");
  src: url("../fonts/CircularStd-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/CircularStd-Black.woff2") format("woff2"),
    url("../fonts/CircularStd-Black.woff") format("woff"),
    url("../fonts/CircularStd-Black.ttf") format("truetype"),
    url("../fonts/CircularStd-Black.svg#CircularStd-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/*Fentes Proyectos*/

/*          Axiforma          */
@font-face {
  font-family: "Axiforma";
  src: url("../fonts/Singulares/Kastelov-Axiforma-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Axiforma Light";
  src: url("../fonts/Singulares/Kastelov-Axiforma-Light.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Axiforma Medium";
  src: url("../fonts/Singulares/Kastelov-Axiforma-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Axiforma Bold";
  src: url("../fonts/Singulares/Kastelov-Axiforma-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

/*         Lora             */
@font-face {
  font-family: "Lora";
  src: url("../fonts/Singulares/Lora/static/Lora-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Lora Medium";
  src: url("../fonts/Singulares/Lora/static/Lora-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Lora Bold";
  src: url("../fonts/Singulares/Lora/static/Lora-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/*         Helvetica */



@font-face {
  font-family: "Helvetica Neue LT Std Light";
  src: url("../fonts/delGredal/HelveticaLTStd-LightObl.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue LT Std";
  src: url("../fonts/delGredal/HelveticaLTStd-Obl.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue LT Std Medium";
  src: url("../fonts/delGredal/HelveticaRoundedLTStd-Bd.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue LT Std Bold";
  src: url("../fonts/delGredal/HelveticaInseratLTStd-Roman.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}


/*                             FUTURA              */

@font-face {
  font-family: "Futura Medium";
  src: url("../fonts/delGredal/futura/FuturaBT-Book.ttf") format("truetype");
  src:
    url("../fonts/delGredal/futura/FuturaBT-Book.woff2") format("woff2"),
    url("../fonts/delGredal/futura/FuturaBT-Book.woff") format("woff"),
    url("../fonts/delGredal/futura/FuturaBT-Book.svg") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Futura Bold";
  src: url("../fonts/delGredal/futuraBold/Futura-Bold.eot");
  src:
    url("../fonts/delGredal/futuraBold/Futura-Bold.woff2") format("woff2"),
    url("../fonts/delGredal/futuraBold/Futura-Bold.woff") format("woff"),
    url("../fonts/delGredal/futuraBold/Futura-Bold.ttf") format("truetype"),
    url("../fonts/delGredal/futuraBold/Futura-Bold.svg") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/*                          MONUMENT                  */

@font-face {
  font-family: "Monument Extended Regular";
  src: url("../fonts/delGredal/monument/MonumentExtended-Regular.eot");
  src:
    url("../fonts/delGredal/monument/MonumentExtended-Regular.woff2") format("woff2"),
    url("../fonts/delGredal/monument/MonumentExtended-Regular.otf") format("opentype"),
    url("../fonts/delGredal/monument/MonumentExtended-Regular.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}

/*                          DIN PRO                     */

@font-face {
  font-family: "DIN Pro";
  src: url("../fonts/linetec/DINPro-Black.eot");
  src: url("../fonts/linetec/DINPro-Black.otf") format("opentype"),
    url("../fonts/linetec/DINPro-Black.woff2") format("woff2"),
    url("../fonts/linetec/DINPro-Black.woff") format("woff"),
    url("../fonts/linetec/DINPro-Black.ttf") format("truetype"),
    url("../fonts/linetec/DINPro-Black.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*                          Reef                     */
@font-face {
  font-family: "Reef";
  src: url("../fonts/linetec/Reef.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

/*                         Bahnschrift                 */
@font-face {
  font-family: "Bahnschrift Light";
  src: url("../fonts/analitica/BAHNSCHRIFT.TTF") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Bahnschrift";
  src: url("../fonts/analitica/BAHNSCHRIFT.TTF") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Bahnschrift Medium";
  src: url("../fonts/analitica/BAHNSCHRIFT.TTF") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Bahnschrift Bold";
  src: url("../fonts/analitica/BAHNSCHRIFT.TTF") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/*                      MONTSERRAT           */

@font-face {
  font-family: "Montserrat Light";
  src: url("../fonts/papeles/Montserrat-Light.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/papeles/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat Medium";
  src: url("../fonts/papeles/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("../fonts/papeles/Montserrat-Bold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/*                   Euclid Circular                           */

@font-face {
  font-family: "Euclid Circular A Light";
  src: url("../fonts/delivery/EuclidCircularA-LightItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("../fonts/delivery/EuclidCircularA-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A Medium";
  src: url("../fonts/papeles/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A Bold";
  src: url("../fonts/delivery/EuclidCircularA-Bold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}


/*                            JUNEGULL RG                                     */

@font-face {
  font-family: "Junegull rg";
  src: url("../fonts/altaCucha/junegull-rg.otf") format("opentype");
}



/*                              CINZEL                                        */


@font-face {
  font-family: "Cinzel";
  src: url("../fonts/GusEspada/CINZEL-VARIABLEFONT_WGHT.TTF") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Cinzel Medium";
  src: url("../fonts/GusEspada/CINZEL-MEDIUM.TTF") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Cinzel Semibold";
  src: url("../fonts/GusEspada/CINZEL-SEMIBOLD.TTF") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Cinzel Bold";
  src: url("../fonts/GusEspada/CINZEL-BOLD.TTF") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/*                                   POPPINS                                          */

@font-face {
  font-family: "Poppins";
  src: url("../fonts/GusEspada/POPPINS-REGULAR_0.TTF") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins Medium";
  src: url("../fonts/GusEspada/POPPINS-MEDIUM_0.TTF") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins Semibold";
  src: url("../fonts/GusEspada/POPPINS-SEMIBOLD_0.TTF") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../fonts/GusEspada/POPPINS-BOLD_0.TTF") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/*                                    Gotham                           */

@font-face {
  font-family: "Gotham Light";
  src: url("../fonts/MuniRioSegundo/Gotham-Light.eot");
  src: url("../fonts/MuniRioSegundo/Gotham-Light.woff2") format("woff2"),
    url("../fonts/MuniRioSegundo/Gotham-Light.woff") format("woff"),
    url("../fonts/MuniRioSegundo/Gotham-Light.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/MuniRioSegundo/gotham-book.eot");
  src: url("../fonts/MuniRioSegundo/gotham-book.woff2") format("woff2"),
    url("../fonts/MuniRioSegundo/gotham-book.woff") format("woff"),
    url("../fonts/MuniRioSegundo/gotham-book.ttf") format("truetype"),
    url("../fonts/MuniRioSegundo/gotham-book.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Medium";
  src: url("../fonts/MuniRioSegundo/Gotham-Medium.eot");
  src: url("../fonts/MuniRioSegundo/Gotham-Medium.woff2") format("woff2"),
    url("../fonts/MuniRioSegundo/Gotham-Medium.woff") format("woff"),
    url("../fonts/MuniRioSegundo/gotham-medium.ttf") format("truetype"),
    url("../fonts/MuniRioSegundo/Gotham-Medium.svg") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Bold";
  src: url("../fonts/MuniRioSegundo/Gotham-Bold.eot");
  src: url("../fonts/MuniRioSegundo/Gotham-Bold.woff2") format("woff2"),
    url("../fonts/MuniRioSegundo/Gotham-Bold.woff") format("woff"),
    url("../fonts/MuniRioSegundo/Gotham-Bold.ttf") format("truetype"),
    url("../fonts/MuniRioSegundo/Gotham-Bold.svg") format("svg");
  font-weight: 800;
  font-style: normal;
}

/*                                  RIGHTROUS                                    */

@font-face {
  font-family: "Righteous";
  src: url("../fonts/bultako/Righteous-Regular.ttf") format(truetype);
  font-weight: 500;
  font-style: normal;
}

/*---------------------*/

*,
*::before,
*::after,
:root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --darkBlue: #00214b;
  --darkBlue-01: #01253b;
  --lightBlue: #36a9e1;
  --lightBlue-01: #005081;
  --lightBlue-02: #0075a9;
  --yellow: #fddb00;
  --circularLight: "Circular Light", Arial, Helvetica, sans-serif;
  --circularBook: "Circular Book", Arial, Helvetica, sans-serif;
  --circularBold: "Circular Bold", Arial, Helvetica, sans-serif;
  --circularBlack: "Circular Black", Arial, Helvetica, sans-serif;
}

html {
  height: 100%;
  /* scroll-behavior: smooth; */
  cursor: url(../img/cursor.svg), pointer;
}

.in-progress {
  cursor: url(../img/cursor-hover.svg), pointer;
}

.section-items {
  cursor: url(../img/img-marcador-portfolio.svg), pointer;
}

body {
  font-size: 16px;
  color: white;
  overflow-x: hidden;
  background: var(--darkBlue);
  font-family: var(--circularLight);
  /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
}

ul {
  list-style-type: none;
}

a {
  cursor: pointer;
  text-decoration: none;
}

h1,
h3 {
  font-family: var(--circularBlack);
}

h2 {
  line-height: 0.9;
  font-family: var(--circularBold);
}

h1 {
  font-size: clamp(50px, 6.2vw, 100px);
}

.h1 {
  font-family: var(--circularBlack);
}

.fs1 {
  font-family: var(--circularBlack);
  font-size: clamp(50px, 6.2vw, 100px);
}

h2 {
  font-size: clamp(50px, 5.2vw, 80px);
}

.h3 {
  font-size: 50px !important;
}

h3 {
  font-size: 50px;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 21px;
}

strong {
  font-family: var(--circularBold);
}

.form-control:focus,
.form-select:focus {
  border: none;
  box-shadow: none;
}

/* Utilities */
.mt-150 {
  margin-top: 150px;
}

.mt--40 {
  margin-top: -40px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.hidden {
  display: none;
}

.hr-lightBlue-01 {
  opacity: 1;
  background: var(--lightBlue-01);
}

.filter-blur {
  filter: blur(2px);
}

.linea-blanca {
  position: relative;
  width: 70%;
  height: 2px;
  background-color: white;
  top: 36%;
  margin-left: 13px;
}

/* Colors */
.bg-darkBlue {
  background: var(--darkBlue);
}

.bg-darkBlue-01 {
  background: var(--darkBlue-01);
}

.bg-lightBlue {
  background: var(--lightBlue);
}

.fc-yellow {
  color: var(--yellow) !important;
}

.fc-darkBlue {
  color: var(--darkBlue);
}

.fc-darkBlue-01 {
  color: var(--darkBlue-01);
}

.fc-lightBlue {
  color: var(--lightBlue);
}

.lightBlue-02 {
  color: var(--lightBlue-02);
}

.white {
  color: white;
}

/* Fonts */
.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-32 {
  font-size: 32px;
}

.fs-48 {
  font-size: 48px;
}

.fs-60 {
  font-size: 60px;
}

.fs-64 {
  font-size: 64px;
}

.fs-74 {
  font-size: 74px;
}

.fs-110 {
  font-size: 110px;
}

.ff-circularBook {
  font-family: var(--circularBook);
}

.ff-circularBold {
  font-family: var(--circularBold);
}

.ff-circularBlack {
  font-family: var(--circularBlack);
  font-size: 4.5vw;
}

.font-circularBlack {
  font-family: "Circular Black", sans-serif;
}

.ff-circularLight {
  font-family: var(--circularLight);
}

/* menu */
#navbar {
  z-index: 101;
  width: 100%;
  height: 6.5rem;
  position: fixed;
  padding: 1.25rem 0;
  transition: all 0.5s ease-in-out;
}

.background-filter {
  backdrop-filter: blur(15px) brightness(80%) contrast(100%);
}

.custom-nav .dropdown .btn {
  background: none;
}

.custom-nav .dropdown-menu {
  border: none;
  position: absolute;
  background: var(--darkBlue);
}

.custom-nav .dropdown-menu .dropdown-item {
  color: white;
  font-family: var(--circularBold);
}

.custom-nav .dropdown-menu .dropdown-item:hover {
  background: var(--lightBlue-02);
}

.custom-nav .dropdown-toggle::after,
.nav-mobile .dropdown-toggle::after {
  margin: 0;
  width: 42px;
  height: 42px;
  border: none;
  vertical-align: middle;
  background: url(../img/icon-feather-chevron-down.svg) center center no-repeat;
}

.custom-nav .btn-white:hover {
  cursor: url(../img/cursor-contacto.svg), pointer !important;
}

.menu-wrap {
  top: 0;
  left: 0;
  z-index: 21;
  width: 100%;
  position: fixed;
}

.toggler {
  opacity: 0;
  z-index: 100;
  width: 60px;
  height: 60px;
  position: absolute;
  cursor: url(../img/cursor.svg), pointer;
}

.hamburger {
  z-index: 100;
  width: 60px;
  padding: 1rem;
}


/* Hamburger line */
.hamburger>div {
  position: relative;
  width: 100%;
  height: 2px;
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: center;
  transition: all 0.4s ease;
}

.hamburger.azul>div {
  background-color: #00214b;
}

/* Top and bottom lines */
.hamburger>div:before,
.hamburger>div:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -10px;
  width: 100%;
  height: 2px;
  background: inherit;
}

/* Moves line down */
.hamburger>div:after {
  top: 10px;
}

/* Toggler animate */
.hamburger.checked>div {
  transform: rotate(135deg);
}

/* Turn Lines into X */
.hamburger.checked>div:before,
.hamburger.checked>div:after {
  top: 0;
  transform: rotate(90deg);
}

/* Rotate on hover when checked */
.toggler:checked:hover+.hamburger>div {
  transform: rotate(225deg);
}

/* Show menu */
.menu-wrap .menu.checked {
  visibility: visible;
}

.menu-wrap .menu.checked>div {
  transform: scale(1);
  transition-duration: 0.75s;
}

.menu-wrap .menu.checked>div>div {
  opacity: 1;
  transition: opacity 0.4s ease;
}

.menu-wrap .menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  visibility: hidden;
  align-items: center;
  justify-content: center;
}

.menu-wrap .menu>div {
  flex: none;
  display: flex;
  border-radius: 50%;
  align-items: center;
  transform: scale(0);
  justify-content: center;
  transition: all 0.4s ease;
}

.menu-wrap .menu>div video {
  position: absolute;
}

.menu-wrap .menu>div>div {
  opacity: 0;
  max-width: 90vw;
  max-height: 100vh;
  text-align: center;
  transition: opacity 0.4s ease;
}

.menu-wrap .menu>div>div>ul>li {
  font-size: 60px;
  list-style: none;
  font-family: var(--circularBook);
}

.menu-wrap .menu>div>div>ul>li>a {
  background-image: linear-gradient(to right,
      var(--yellow),
      var(--yellow) 50%,
      #ffff 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.menu-wrap .menu>div>div>ul>li>a:before {
  content: "";
  left: 0;
  width: 0;
  height: 3px;
  bottom: -3px;
  display: block;
  position: absolute;
  background: var(--yellow);
  transition: all 0.3s ease-in-out;
}

.menu-wrap .menu>div>div>ul>li>a:hover {
  background-position: 0;
  cursor: url(../img/cursor.svg), pointer;
}

.menu-wrap .menu>div>div>ul>li>a:hover::before {
  width: 100%;
}

.fb-lightBlue {
  color: var(--darkBlue);
  -webkit-text-stroke: 2px var(--lightBlue);
}

.fb-lightBlue-01 {
  color: var(--darkBlue-01);
  -webkit-text-stroke: 2px var(--lightBlue);
}

.fb-white {
  color: var(--darkBlue);
  -webkit-text-stroke: 2px white;
}

.fb-transparent {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1px white;
}

/* Buttons */
.btn,
a {
  cursor: url(../img/cursor-hover.svg), pointer;
}

.btn {
  padding: 12px 20px;
  font-size: 21px;
}

#navbar .btn {
  font-size: 16px;
}

.btn-power {
  animation: pulse 2s infinite;
}

.btn-power path {
  transition: all 0.3s ease-in;
}

.btn-power:hover path.color {
  fill: #36a9e1;
}

.btn-white {
  background: white;
  color: var(--lightBlue-01);
  font-family: var(--circularBold);
}

.btn-white:hover {
  color: white;
  background: var(--lightBlue-01);
}

.btn-outline-primary {
  color: white;
  border: 2px solid white;
  font-family: var(--circularBold);
}

.btn-outline-primary:hover {
  background: white;
  border-color: white;
  color: var(--darkBlue);
}

.btn-outline-primary-icon {
  color: white;
  border-radius: 6px;
  position: relative;
  border: 2px solid white;
  display: inline-flex;
  transition: all 0.3s;
}

.btn-outline-primary-icon:hover {
  color: white;
  padding-right: 56px;
  cursor: url(../img/cursor-hover.svg), pointer;
}

.btn-outline-primary-icon img {
  opacity: 0;
  position: absolute;
  transition: all 0.3s;
  right: 5px;
}

.btn-outline-primary-icon:hover img {
  opacity: 1;
}

.btn-outline-blue {
  border-radius: 50px;
  color: var(--lightBlue-02);
  border: 2px solid var(--lightBlue-01);
}

.btn-outline-blue:hover {
  color: white;
  border: 2px solid white;
}

.btn-outline-darkBlue-01 {
  font-size: 21px;
  border-radius: 6px;
  color: var(--darkBlue-01);
  font-family: var(--circularBold);
  border: 2px solid var(--darkBlue-01);
}

.btn-outline-darkBlue-01:hover {
  color: white;
  background: var(--darkBlue-01);
}

.btn-yellow {
  border-radius: 6px;
  color: var(--darkBlue);
  background: var(--yellow);
  border: 2px solid var(--yellow);
  font-family: var(--circularBold);
}

.btn-yellow:hover {
  color: white;
  background: var(--lightBlue);
  border: 2px solid var(--lightBlue);
}

.btn-blue-outline-white {
  color: white;
  font-size: 21px;
  border-radius: 6px;
  background: var(--lightBlue);
  font-family: var(--circularBold);
  border: 2px solid var(--lightBlue);
}

.btn-blue-outline-white:hover {
  color: white;
  background: var(--lightBlue-02);
  border: 2px solid var(--lightBlue-02);
  cursor: url(../img/cursor-hover.svg), pointer;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%);
}

.doble-text {
  height: 130px;
}

.doble-text .fs1 {
  margin-bottom: 0;
  font-size: 140px;
  line-height: 1;
}

.doble-text .word {
  opacity: 0;
  position: absolute;
}

.doble-text .w1 {
  animation: w1anim 10s infinite;
}

.doble-text .w2 {
  animation: w2anim 10s infinite;
}

.doble-text .w3 {
  animation: w3anim 10s infinite;
}

.doble-text .w4 {
  animation: w4anim 10s infinite;
}

@keyframes w1anim {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }
}

@keyframes w2anim {
  30% {
    opacity: 0;
  }

  45% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

@keyframes w3anim {
  50% {
    opacity: 0;
  }

  65% {
    opacity: 1;
  }

  70% {
    opacity: 0;
  }
}

@keyframes w4anim {
  70% {
    opacity: 0;
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.hover-text {
  cursor: pointer;
  position: relative;
}

.hover-text span {
  left: 50%;
  bottom: 0;
  opacity: 0;
  font-size: 20px;
  padding: 9px 16px;
  position: absolute;
  transition: all 0.3s;
  border-radius: 50px;
  color: var(--lightBlue);
  transform: translate(-50%, 70%);
  font-family: var(--circularBook);
  border: 2px solid var(--lightBlue);
}

.hover-text:hover span {
  opacity: 1;
  transform: translate(-50%, 100%);
}

#parallax {
  height: 100vh;
  overflow: hidden;
  position: relative;
  padding-top: 200px;
  background: var(--lightBlue) !important;
}

#parallax .img {
  z-index: 1;
  bottom: 0;
  position: absolute;
}

#parallax .img-01 {
  bottom: 0px;
  z-index: 0;
  width: 100%;
  left: 0;
  position: absolute;
}

#parallax .img-02 {
  bottom: 0px;
  z-index: 2;
  width: 100%;
  left: 0;
  position: absolute;
}

.header-animation {
  opacity: 0;
  font-size: 50px;
  display: inline-block;
  text-transform: uppercase;
  animation: fadeIn 1.5s forwards;
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.header-animation .first {
  z-index: 1;
  position: relative;
  display: inline-block;
  animation: firstSpan 1.5s forwards cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.header-animation .animation {
  overflow: hidden;
  display: inline-flex;
}

.header-animation .animation span {
  z-index: -1;
  font-weight: 100;
  display: inline-block;
  font-family: var(--circularBook);
  animation: secondSpan 1.5s forwards cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes firstSpan {
  0% {
    transform: translateY(50%);
  }

  60% {
    transform: translateY(50%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes secondSpan {
  0% {
    transform: translateY(-100%);
  }

  60% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(-0%);
  }
}

#posicionamiento-2 {
  padding: 100px 0 200px;
  background: url(../img/bg-parallax-04.png) right bottom no-repeat;
}

.w-vulcan-icon-wrapper,
.w-bottom-bar-lower {
  display: none !important;
}

#posicionamiento .box,
#testimonio-chimpa .box {
  padding: 50px 40px;
  border-radius: 32px;
  position: relative;
  border: 2px solid white;
  -webkit-backdrop-filter: blur(8px) brightness(150%) contrast(77%);
  backdrop-filter: blur(8px) brightness(100%) contrast(77%);
}

/* Carrusel logos nuevo */
:root {
  --color-bg: var(--darkBlue);
  --color-bg-accent: var(--lightBlue-01);
  --size: clamp(0rem, 0rem + 24vmin, 26rem);
  --gap: calc(var(--size) / 14);
  --duration: 60s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}

.wrapper-parent {
  display: grid;
  align-content: center;
  overflow: hidden;
  gap: var(--gap);
  width: 100%;
  min-height: 100vh;
  font-size: 1rem;
  line-height: 1.5;
  background-color: var(--color-bg);
}

.marquee {
  display: flex;
  overflow: hidden;
  margin-bottom: 1%;
  user-select: none;
  -webkit-mask-image: linear-gradient(var(--mask-direction, to right),
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 20%,
      hsl(0 0% 0% / 1) 80%,
      hsl(0 0% 0% / 0));
  mask-image: linear-gradient(var(--mask-direction, to right),
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 20%,
      hsl(0 0% 0% / 1) 80%,
      hsl(0 0% 0% / 0));
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 100%;
  animation: scroll-x var(--duration) linear infinite;
}

.margin-marquee {
  margin-left: 0.6%;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee--reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: -3s;
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }

  to {
    transform: translateX(var(--scroll-end));
  }
}

@keyframes scroll-y {
  from {
    transform: translateY(var(--scroll-start));
  }

  to {
    transform: translateY(var(--scroll-end));
  }
}

/* Element styles */
.marquee svg {
  display: grid;
  place-items: center;
  width: var(--size);
  fill: var(--color-text);
  background: var(--color-bg-accent);
  aspect-ratio: 16/9;
  padding: calc(var(--size) / 10);
  border-radius: 0.5rem;
}

.marquee--vertical svg {
  aspect-ratio: 1;
  width: calc(var(--size) / 1.5);
  padding: calc(var(--size) / 6);
}

/* Gradient images branding */

.gradient::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
}

.gradientes-branding-carousel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%);
  opacity: 1;
  z-index: 1;
  border-radius: 8px;
}

.gradient:hover::before {
  opacity: 1;
}

/* Parent wrapper */
.wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 100vw;
}

.image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.wrapper {
  width: 100vw;
  position: fixed;
  z-index: 105;
  min-height: 100vh;
  height: 100%;
  top: 34%;
}

.image-wrapper {
  position: fixed;
  z-index: 105;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 300px;
  height: 100%;
  width: 100%;

  transition: max-width 0.8s ease, top 0.8s ease, transform 0.8s ease,
    max-heigth 0.8s ease;
  transition-delay: 2s;
}

.wistia-view> :first-child {
  display: none;
}

.image-wrapper.active {
  animation: nombreActivo 0.8s ease 0.1s 1 forwards;
  border: none !important;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: linear-gradient(to right, #00204bfa 26%, #00204b75);
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.close-img {
  top: 15px;
  right: 20px;
}

.btn-expand {
  z-index: 5;
  background: #ffffff !important;
  color: var(--darkBlue) !important;
  font-weight: bold !important;
  width: fit-content;
  margin-top: 15px;
}

.title-image .title {
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 1;
  letter-spacing: 1.3px;
}

.title-image .link {
  color: var(--yellow);
}

-projects .title {
  font-weight: bold;
}

.text-yellow {
  color: var(--yellow);
}

.circle-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid var(--yellow);
  padding: 25px;
  border-radius: 50%;
}

.first-slide,
.second-slide {
  user-select: none;
}

.first-slide {
  opacity: 1;
  transition: 0.7s ease all;
}

.second-slide {
  opacity: 0;
  transition: 0.7s ease all;
}

.fa-arrow-down {
  transition: 0.7s ease all;
}

.circle-arrow {
  position: absolute;
  bottom: 5vh;
}

.image:hover {
  background: #00214b40;
}

@keyframes nombreActivo {
  to {
    max-width: 100vw;

    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
  }
}

.wrapper--vertical {
  flex-direction: row;
  height: 100vh;
}

#posicionamiento h2 {
  font-size: 45px;
}

#posicionamiento .box .img {
  top: 30px;
  right: 40px;
  position: absolute;
}

#posicionamiento .box h2 span {
  color: white;
  font-family: var(--circularLight);
}

#posicionamiento .box ul li a {
  text-decoration: underline;
}

#portfolio {
  z-index: 10;
  overflow: hidden;
  padding: 20vh 0;
  position: relative;
  background:
    var(--darkBlue) center right 15% no-repeat;
}

#portfolio ul li {
  cursor: pointer;
  width: 100%;
  cursor: url(../img/cursor-hover.svg), pointer;
  font-family: var(--circularBlack);
}

#portfolio ul li span {
  font-size: 100px;
  line-height: 1.2;
  position: relative;
  transition: all 0.3s;
  color: rgba(255, 255, 255, 0.3);
  z-index: 1;
}

#portfolio ul li:hover span {
  color: white;
  cursor: url(../img/cursor-hover.svg), pointer;
}

#portfolio ul li span img {
  top: 50%;
  opacity: 0;
  left: -20px;
  width: 100px;
  transition: all 0.3s;
  object-fit: contain !important;
  transform: translate(-100%, -50%);
}

#portfolio ul li:hover span img,
#portfolio ul li:hover video {
  opacity: 1;
  z-index: 0;
}

#portfolio ul li img,
#portfolio ul li video {
  top: 50%;
  left: 50%;
  opacity: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.4s;
  pointer-events: none;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

#portfolio ul li video {
  top: 50%;
  left: 50%;
  opacity: 0;
  z-index: -1;
  width: 100%;
  /* height: auto; */
  position: absolute;
  transition: all 0.4s;
  pointer-events: none;
  transform: translate(-50%, -50%);
}

#portfolio ul li:hover img {
  opacity: 1;
  pointer-events: none;
}

#portfolio-card .box-colapse {
  height: 200px;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
  padding: 30px 20px;
  border-radius: 14px;
  transition: all 0.3s;
}

#portfolio-card .bg-01 {
  background: linear-gradient(rgba(0, 33, 75, 0.4), rgba(0, 33, 75, 0.4)),
    url(../img/bg-collapse-01.png) center center no-repeat;
  background-size: cover;
}

#portfolio-card .bg-01.boxActive {
  height: 400px;
  background: linear-gradient(rgba(0, 33, 75, 0.7), rgba(0, 33, 75, 0.7)),
    url(../img/bg-collapse-01.png) center center no-repeat;
  background-size: cover;
}

#portfolio-card .bg-02 {
  background: linear-gradient(rgba(0, 33, 75, 0.4), rgba(0, 33, 75, 0.4)),
    url(../img/bg-collapse-02.png) center center no-repeat;
  background-size: cover;
}

#portfolio-card .bg-02.boxActive {
  height: 400px;
  background: linear-gradient(rgba(0, 33, 75, 0.7), rgba(0, 33, 75, 0.7)),
    url(../img/bg-collapse-02.png) center center no-repeat;
  background-size: cover;
}

#portfolio-card .bg-03 {
  background: linear-gradient(rgba(0, 33, 75, 0.4), rgba(0, 33, 75, 0.4)),
    url(../img/bg-collapse-03.png) center center no-repeat;
  background-size: cover;
}

#portfolio-card .bg-03.boxActive {
  height: 400px;
  background: linear-gradient(rgba(0, 33, 75, 0.7), rgba(0, 33, 75, 0.7)),
    url(../img/bg-collapse-03.png) center center no-repeat;
  background-size: cover;
}

#portfolio-card .box-colapse h1 {
  font-size: 36px;
}

#portfolio-card .box-colapse .btn-plus,
#portfolio-card .box-colapse .btn-minus {
  top: 30px;
  right: 20px;
  position: absolute;
  transition: all 0.3s;
}

#portfolio-card .box-colapse .btn-plus {
  opacity: 1;
}

#portfolio-card .box-colapse.boxActive .btn-plus {
  opacity: 0;
}

#portfolio-card .box-colapse .btn-minus {
  opacity: 0;
}

#portfolio-card .box-colapse.boxActive .btn-minus {
  opacity: 1;
}

#portfolio-card .box-colapse .content {
  opacity: 0;
  font-size: 16px;
  margin-top: 20px;
  transition: all 0.3s;
  font-family: var(--circularLight);
}

#portfolio-card .box-colapse.boxActive .content {
  opacity: 1;
}

#portfolio-card .box-colapse .tag .tag-outline {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 50px;
  margin-bottom: 10px;
  display: inline-block;
  font-family: var(--circularBook);
  border: 2px solid white;
}

.tag-outline {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 50px;
  margin-bottom: 10px;
  display: inline-block;
  font-family: var(--circularBook);
  border: 2px solid white;
}

#portfolio-card .box-colapse .tag {
  bottom: 20px;
  height: 40px;
  overflow: hidden;
  position: absolute;
}

#portfolio-card .box-colapse.boxActive .tag {
  overflow: visible;
  height: max-content;
}

.rounded-video {
  border-radius: 1rem;
}

#text-doble {
  padding: 100px 0;
  margin-top: 100px;
  background: url(../img/text-partner.webp) left top,
    url(../img/bg-somos.png) left bottom;
  background-repeat: no-repeat;
  background-size: 70%, 100%;
}

#contactanos {
  z-index: 7;
  padding: 150px 0;
  position: relative;
  overflow: hidden;
}

#contactanos .btn-outline-primary-icon {
  z-index: 2;
  border: 2px solid;
}

#contactanos .img-03 {
  right: 10%;
  bottom: -8px;
  z-index: 0;
  position: absolute;
  width: 20vw;
}

#contactanos .img-04 {
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  width: clamp(40rem, 40vw, 60rem);
}

#premios {
  background: #00214b url(../img/text-premios.webp) left top 65px no-repeat;
  background-size: 50%;
  z-index: 7;
}

#premios .box-premio {
  width: 50%;
  height: 250px;
}

#premios .box-premio img {
  position: absolute;
}

#premios .box-premio img:first-child {
  z-index: 10;
  transition: all 0.4s;
  margin-bottom: 40px;
}

#premios .box-premio img:first-child:hover {
  transform: scale(1.3);
}

#premios .img {
  top: 50%;
  width: 30%;
  right: -60px;
  position: absolute;
  transform: translate(0, -50%);
}

#premios h5 {
  width: fit-content;
  position: relative;
}

#premios h5::before {
  content: "";
  left: 0;
  width: 100%;
  bottom: -10px;
  position: absolute;
  border-bottom: 2px solid var(--lightBlue);
}

#premios a {
  color: var(--lightBlue);
  transition: all 0.3s ease;
}

#premios a:hover {
  color: white;
}

#reconocimientos {
  z-index: 2;
  overflow: hidden;
}

#reconocimientos .testimonios-body {
  padding: 60px;
  border-radius: 32px;
  border: 2px solid white;
  background: rgb(0, 117, 169, 45%);
  -webkit-backdrop-filter: blur(45px);
  backdrop-filter: blur(45px);
}

#reconocimientos .testimonios-body .img {
  margin-bottom: 25px;
  width: 170px !important;
}

#reconocimientos .testimonios-body .owl-theme .owl-nav {
  text-align: left;
}

#reconocimientos .testimonios-body .owl-theme .owl-nav [class*="owl-"] {
  width: 42px;
  height: 42px;
  border-radius: 50px;
  transition: all 0.4s ease;
  background: var(--lightBlue);
}

#reconocimientos .testimonios-body .owl-theme .owl-nav [class*="owl-"]:hover {
  background: var(--yellow);
  cursor: url(../img/cursor-hover.svg), pointer;
}

#reconocimientos .testimonios-body .owl-theme .owl-nav [class*="owl-"]:hover svg path {
  fill: var(--lightBlue);
}

#reconocimientos .carousel-box {
  left: 0;
  top: 50%;
  z-index: -1;
  position: absolute;
  transform: translate(0, -50%);
}

#testimonio-chimpa {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

#testimonio-chimpa dotlottie-player {
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  filter: opacity(0.5);
  transform: translateX(-50%) translateY(-50%);
}

#testimonio-chimpa .box h2 {
  margin-bottom: -20px;
}

#parallax #bg-video,
#testimonio-chimpa #bg-video {
  right: 0;
  bottom: 0;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  filter: opacity(0.5);
}

#form {
  z-index: 2;
  position: relative;
  background: url(../img/bg-form.jpg) center center no-repeat;
  background-size: cover;
}

.bg-form {
  padding: 90px;
  background: #e8f0fb;
  border-radius: 22px;
}

.content-form {
  overflow: hidden;
  height: 0px;
  transition: all 0.8s ease-in;
}

.scroll {
  transition: all 0.8s ease-in;
  height: 200px;
}

.rocket {
  min-height: 511px;
}

#form input,
#form .custom-select {
  border: 0;
  font-size: 18px;
  padding-left: 0;
  border-radius: 0;
  background: none;
  position: relative;
  color: var(--darkBlue-01);
  font-family: var(--circularBook);
  /* border-bottom: 1px solid var(--darkBlue-01); */
}

#form .custom-select select {
  display: none;
}

#form .custom-select span {
  border: none !important;
  background: none;
}

#form .select-selected {
  background: none;
}

/* Style the arrow inside the select element: */
#form .select-selected:after {
  content: "";
  right: 0;
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  border-bottom: 2px solid var(--darkBlue-01);
  border-left: 2px solid var(--darkBlue-01);
  border-bottom-left-radius: 2px;
  transform-origin: center center;
  transform: rotate(-45deg) translate(0%, 0%);
  transition: transform ease-in-out 200ms;
}

#form .select-selected.select-arrow-active:after {
  transform: rotate(135deg) translate(50%, 0%);
}

#form .select-selected {
  color: var(--darkBlue) !important;
}

#form .select-selected {
  padding-left: 0 !important;
}

#form .select-items div,
#form .select-selected {
  display: flex;
  font-size: 18px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  border: 1px solid transparent;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
}

/* Style items (options): */
#form .select-items {
  left: 0;
  right: 0;
  top: 52px;
  z-index: 99;
  position: absolute;
  background: white;
}

/* Hide the items when the select box is closed: */
#form .select-hide {
  display: none;
}

#form .select-items div:hover,
.same-as-selected {
  color: white;
  background: var(--darkBlue-01);
}

#form .btn-send {
  width: 100%;
  display: flex;
  color: white;
  font-size: 21px;
  border-bottom: 5px;
  align-items: center;
  justify-content: center;
  background: var(--lightBlue);
  font-family: var(--circularBold);
}

#form .btn-send:hover {
  color: white;
  background: var(--darkBlue-01);
  cursor: url(../img/cursor-ok.svg), pointer;
}

#form .btn-send svg path {
  transition: all 0.3s;
}

#form .btn-send:hover svg path {
  fill: white;
}

#form .form-control::placeholder {
  color: var(--darkBlue);
}

#contact-mobile {
  bottom: 0;
  width: 100%;
  z-index: 100;
  position: fixed;
  padding: 20px 0;
  -webkit-backdrop-filter: blur(15px) brightness(80%) contrast(100%);
  backdrop-filter: blur(15px) brightness(80%) contrast(100%);
  border-radius: 15px 15px 0 0;
  background-color: transparent !important;
}

#contact-mobile a {
  color: white;
}

/* footer */
footer {
  padding: 50px 0;
  background: url(../img/bg-footer.png) center bottom no-repeat;
  background-size: cover;
}

footer a svg path {
  transition: all 0.3s;
}

footer a:hover svg path {
  fill: white;
}

.pointer {
  cursor: url(../img/cursor-hover.svg) !important;
}

/* === */

/* PRELOADER */
#loading {
  background-color: var(--darkBlue);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
  opacity: 1;
}

/* === Cookies === */
.cookie-disclaimer {
  -webkit-backdrop-filter: blur(12px) brightness(90%) contrast(77%);
  backdrop-filter: blur(12px) brightness(90%) contrast(77%);
  color: #fff;
  opacity: 1;
  width: 650px;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  height: auto;
  position: fixed;
  border-radius: 10px;
  border: 2px solid white;
}

.cookie-disclaimer .container {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.cookie-disclaimer .cookie-close {
  float: right;
  padding: 30px;
  cursor: pointer;
}

/* === fin cookies === */

/*Carousel proyectos detalle*/
.custom-prev-arrow {
  left: 10px;
}

.custom-next-arrow {
  right: 10px;
}

/* ---------------*/

/* Detalle Proyectos */

.detalle-proyecto-titulo {
  font-size: 4vw;
}

.detalle-proyecto-subtitulo {
  font-size: 2vw;
}

.paleta-titulo {
  height: 45vh;
  width: 100vw;
  padding-left: 8%;
}

.seccion-paleta-color {
  height: 25vh;
}

.titulo-seccion-fuente {
  font-size: 6vw;
}

.to-top {
  top: -50px;
  transform: translateX(370px) !important;
}

#loader lottie-player {
  width: 180px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }

  65% {
    transform: translate(0, 20px);
  }

  to {
    transform: translate(0, -0px);
  }
}

.vertical-text-container {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.vertical-text {
  writing-mode: vertical-rl;
  font-size: 11px;
}

.vertical-text-container::before {
  content: "";
  position: absolute;
  top: 5vh;
  transform: translateX(-50%);
  width: 1px;
  height: 10%;
  background-color: white;
}

.rotate-image {
  transform: rotate(90deg);
  height: 25px;
  margin-bottom: 9vh;
}

.position-vertical-container {
  position: absolute;
  top: 15%;
  right: 5%;
}

/* .vertical-text-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  } */

/* .vertical-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .vertical-line {
    width: 1px;
    height: 100%;
    background-color: white;
  }

  .vertical-text {
    writing-mode: vertical-rl;
    font-size: 12px;
    margin-top: 10px;
  } */

/* media */

@media only screen and (max-width: 767px) {

  #parallax #bg-video,
  #testimonio-chimpa #bg-video {
    width: 100%;
  }

  html,
  body {
  }

  .header-animation {
    z-index: 4;
    position: relative;
    font-size: 1.4rem;
  }

  .doble-text .fs1 {
    margin-bottom: 0;
    font-size: 40px;
  }

  #parallax {
    background: none;
  }

  #parallax .img-01,
  #parallax .img-02 {
    display: none;
  }

  .pt-100 {
    padding-top: 50px;
  }

  .pb-100 {
    padding-bottom: 50px;
  }

  .fs1 {
    font-size: 80px;
  }

  h2 {
    font-size: 50px;
  }

  .h3 {
    font-size: 40px !important;
  }

  h3 {
    font-size: 40px !important;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 18px;
  }

  .fs-64 {
    font-size: 48px;
  }

  .nav-mobile .dropdown-flags {
    font-family: var(--circularBook) !important;
  }

  .menu-wrap .menu>div>div>ul>li {
    font-size: 30px;
  }

  .dropdown-item {
    color: white;
    border: none;
  }

  .nav-mobile .dropdown-menu {
    border: none;
    color: white;
    font-size: 30px;
    background: none;
    font-family: var(--circularBook) !important;
  }

  .menu-wrap .menu>div>div>ul>li img {
    width: 65px;
  }

  .menu-wrap .menu>div>div>ul>li a img {
    width: 30px;
  }

  #posicionamiento .box {
    padding: 30px;
  }

  #posicionamiento-2 {
    padding: 0;
    background: none;
  }

  #posicionamiento-2 .img {
    bottom: 0;
    z-index: 2;
    position: absolute;
  }

  #contactanos {
    padding: 75px 0 160px;
  }

  #contactanos h2 {
    font-size: 30px;
  }

  #contactanos .img-03 {
    width: 40%;
    bottom: -100px;
  }

  #contactanos .img-04 {
    width: 100%;
  }

  #text-doble {
    margin: 0;
    padding: 60px 0;
    background: var(--darkBlue) url(../img/bg-text-doble-mobile.png) bottom center no-repeat;
    background-size: contain;
  }

  #text-doble .fs1,
  #solution h1 {
    font-size: 45px;
  }

  #text-doble h2 {
    font-size: 30px !important;
  }

  #text-doble .doble-text {
    height: 55px;
  }

  #reconocimientos .testimonios-body {
    border: none;
    border-radius: 0;
    padding: 30px 15px;
    margin-bottom: -4em;
    background: var(--darkBlue-01);
  }

  #reconocimientos .testimonios-body .owl-item p:last-child {
    color: var(--yellow);
  }

  #reconocimientos .owl-theme .owl-nav.disabled+.owl-dots {
    position: absolute;
    left: 50%;
    bottom: -20px;
    transform: translate(-50%, 0px);
  }

  #reconocimientos .carousel-box {
    position: relative;
    transform: translate(0, 0);
  }

  #portfolio-mobile {
    padding: 50px 0;
    background: url(../img/bg-portfolio-mobile.png) center center no-repeat;
    background-size: cover;
  }

  #portfolio-mobile .nav-link {
    margin: 0 8px;
    color: white;
    font-size: 15px;
    padding: 8px 18px;
    border-radius: 50px;
    font-family: var(--circularLight);
    border: 2px solid rgba(255, 255, 255, 0.2);
  }

  #portfolio-mobile .tab-menu {
    overflow-x: scroll;
  }

  #portfolio-mobile .tab-menu::-webkit-scrollbar {
    display: none;
  }

  #portfolio-mobile .tab-menu .nav {
    width: max-content;
  }

  #portfolio-mobile .nav-pills .nav-link.active {
    background: none;
    border: 2px solid var(--yellow);
    font-family: var(--circularBold);
  }

  .owl-theme .owl-dots .owl-dot.active span {
    background: var(--yellow);
  }

  #premios .img {
    display: none;
  }

  #premios h2 {
    font-size: 30px;
  }

  #premios .box-premio img:first-child {
    margin-bottom: 0;
  }

  #premios h5::before {
    display: none;
  }

  #premios .box-premio {
    margin-bottom: 13px;
    width: auto;
    height: 175px;
    border-radius: 10px;
    background: var(--darkBlue);
  }

  #premios-mobile hr {
    opacity: 1;
  }

  #premios-mobile a {
    color: var(--lightBlue);
  }

  .bg-form {
    padding: 0 12px;
    background: none;
  }

  #form {
    padding: 100px 0;
  }

  /* #form input {
        padding-left: 16px;
        padding-left: 0;
        border-radius: 0 7px 7px 0;
        border-top: 1px solid var(--darkBlue-01);
        border-right: 1px solid var(--darkBlue-01);
        border-bottom: 1px solid var(--darkBlue-01);
    }

    #form span {
        width: 56px;
        background: none;
        border-right: none;
        border-radius: 7px 0 0 7px !important;
        border-top: 1px solid var(--darkBlue-01);
        border-left: 1px solid var(--darkBlue-01);
        border-bottom: 1px solid var(--darkBlue-01);
    } */

  #form label {
    display: none;
  }

  #form span svg path {
    fill: var(--darkBlue-01);
  }

  #form .select-selected:after {
    right: 10px;
    border-bottom: 2px solid var(--darkBlue-01);
    border-left: 2px solid var(--darkBlue-01);
  }

  #form .form-control::placeholder {
    font-size: 16px;
    color: var(--darkBlue-01);
  }

  #form .custom-select {
    border-radius: 7px;
    border: 1px solid var(--darkBlue-01);
  }

  .bg-form-input {
    padding: 38px 32px;
    border-radius: 30px;
    background: #e8f0fb;
  }

  footer {
    padding: 30px 0 110px;

    background-size: contain;
  }

  .wrapper-parent {
    min-height: 28vh;
    padding-top: 60px;
  }

  .fs-60.branding {
    font-size: 60px;
    padding-left: 0% !important;
    margin-top: 20px;
  }

  li.ff-circularBlack {
    font-size: 6.5vw;
  }

  #navbar {
    position: absolute;
  }

  .fs-74.branding2 {
    font-size: 65px !important;
  }
}

@media (max-width: 1366px) {
  .fs-64 {
    font-size: 54px;
  }

  #portfolio ul li span {
    font-size: 80px;
    line-height: 1.1;
  }

  .doble-text {
    height: 67px;
  }

  .doble-text .fs1 {
    font-size: 80px;
  }

  .scroll-slide {
    flex: 1 0 91vw;
  }

  .header-animation {
    font-size: 2.3rem;
  }

  #contactanos .img-04 {
    right: -123px;
  }
}

.img-branding{
  height: 484px;
  border-radius: 15px;
  object-fit: cover;
  width: 100%;
}

@media (max-width: 1200px) {
  #contactanos .img-03 {
    width: 25vw;
  }

  .fs-110 {
    font-size: 80px;
  }

  /* .doble-text h1 {
        margin-bottom: 10px;
        font-size: 60px;
    } */
  .header-animation {
    font-size: 30px;
  }
}

@media only screen and (max-width: 992px) {
  .head-branding {
    position: relative;
    left: -3%;
  }

  .position-vertical-container {
    position: absolute;
    top: 20%;
    right: 0%;
  }

  .to-top {
    width: 70px;
    top: -40px;
    transform: translateX(230px) !important;
  }

  .marginRowBrandingTablet {
    margin-bottom: 0.5rem !important;
    min-height: 200px !important;
  }

  .scroll {
    height: 86px;
  }

  .scroll img {
    width: 20vw;
  }

  .rocket {
    width: 88vw !important;
    min-height: 170px;
  }

  .scroll-slide {
    max-width: 94vw;
  }

  .fs-110 {
    font-size: 70px;
  }

  .margin-scroll {
    margin-left: 0vw;
  }

  .px-5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}

/* Page Desarrollo Web */
header.desarrollo-web {
  height: 100vh;
  background: url(../img/img-header-desarrollo-web.svg) right bottom no-repeat,
    url(../img/bg-header-desarrollo-web.jpg) center center no-repeat;
  background-size: 43vw, cover;
}

header.desarrollo-web h4 {
  font-size: 57px;
  font-family: var(--circularBook);
}

#slider_desarrollo {
  overflow-x: scroll;
  scroll-behavior: smooth;
}

#slider-desarrollo .nav,
#slider-desarrollo-mobile .nav {
  top: 50px;
  z-index: 4;
  overflow: auto;
  position: absolute;
}

#slider-desarrollo .prev-slide svg {
  width: 10px;
  transform: translate(-25%, 0px);
}

#slider-desarrollo .next-slide svg {
  width: 10px;
}

#slider-desarrollo-mobile .nav div {
  width: max-content;
}

#slider-desarrollo-mobile .mb-35 {
  margin-bottom: 35%;
}

#slider-desarrollo .owl-carousel .container,
#slider-desarrollo-mobile .owl-carousel .container {
  height: 100vh;
}

#slider-desarrollo .owl-nav {
  top: 50%;
  left: 5%;
  width: 90%;
  display: flex;
  position: absolute;
  justify-content: space-between;
}

#slider-desarrollo .owl-nav [class*="owl-"] {
  width: 42px;
  height: 42px;
  border-radius: 50px;
  transition: all 0.4s ease;
  border: 2px solid white;
}

#slider-desarrollo .owl-nav [class*="owl-"]:hover {
  background: var(--lightBlue);
  border-color: var(--lightBlue);
  cursor: url(../img/cursor-hover.svg), pointer;
}

#slider-desarrollo .slidetabs {
  color: white;
  /* font-size: 22px; */
  padding: 10px 40px;
  border-radius: 50px;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  font-family: var(--circularBold);
}

.slidetabs .active-tab {
  border: 2px solid var(--yellow) !important;
}

#slider-desarrollo-mobile .nav a {
  color: white;
}

#slider-desarrollo .h1 {
  font-size: 60px;
  line-height: 65px;
}

#slider-desarrollo h5 .img {
  left: -40px;
  position: absolute;
  width: auto !important;
}

#slider-desarrollo .item,
#slider-desarrollo-mobile .item {
  display: block;
  position: relative;
}

.slider-item-h {
  height: 100vh !important;
}

#slider-desarrollo .item .img {
  height: calc(80vh - 7.5rem);
}

#slider-desarrollo .item .bg,
#slider-desarrollo-mobile .item .bg,
#slider-exito-mobile .item .bg,
#slider-branding .item .bg {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

#slider-exito-mobile {
  height: 100vh;
  background: url(../img/mockup/img-slider-mobile-back.jpg) center center no-repeat;
  background-size: cover;
}

#slider-desarrollo-mobile .img,
#slider-exito-mobile .img,
#slider-branding .img {
  width: 22px !important;
}

#slider-desarrollo-mobile .owl-theme .owl-nav.disabled+.owl-dots {
  position: absolute;
  left: 50%;
  bottom: 90px;
  transform: translate(-50%, 0px);
}

#slider-exito-mobile .owl-theme .owl-nav.disabled+.owl-dots {
  position: absolute;
  left: 50%;
  bottom: -100px;
  transform: translate(-50%, 0px);
}

#slider-branding .owl-theme .owl-nav.disabled+.owl-dots {
  position: absolute;
  left: 50%;
  width: 100%;
  top: -120px;
  transform: translate(-50%, 0px);
}

#slider-branding .owl-theme .owl-dots .owl-dot span {
  width: 6vw;
  height: 2px;
}

section#slider-branding {
  margin-top: 65px;
}

#slider-desarrollo .btn-blue-outline-white {
  background: none;
}

#tu-marca {
  background: url(../img/bg-tu-sitio.png) bottom 100px center no-repeat;
  background-size: contain;
  padding-bottom: 50px;
}

#banner-desarrollo {
  padding: 50px 0;
  position: relative;
  background: url(../img/bg-banner.jpg) center center no-repeat;
  background-size: cover;
}

#banner-desarrollo h3 {
  font-size: 39px;
}

#banner-desarrollo .img {
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  width: clamp(4rem, 40vw, 40rem);
}

#banner-proyectos {
  height: 100vh;
  display: flex;
  align-items: center;
}

#banner-proyectos .contenedor-video {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
}

#banner-proyectos .contenedor-video .gradiente {
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  position: absolute;
  background: rgb(0, 44, 88);
  background: linear-gradient(90deg,
      rgba(0, 44, 88, 1) 0%,
      rgba(0, 34, 68, 0.7) 70%);
}

#banner-proyectos .contenedor-video video {
  z-index: 1;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
}

#banner-proyectos .container {
  z-index: 3;
}

#tecnologias {
  padding: 100px 0;
  margin-top: 100px;
  background-size: 100%;
}

.tecnologia {
  background: url(../img/text-tecnologias.svg) top left no-repeat;
}



#tecnologias h1 {
  margin-bottom: 100px;
}

#desarrollo-contacto {
  padding: 150px 0;
  background: url(../img/bg-desarrollo-01.jpg) center center no-repeat;
  background-size: cover;
}

#ux-desarrollo {
  margin: 100px 0;
  padding: 100px 0 300px;
  background: url(../img/img-desarrollo-ux.svg) center right 10% no-repeat,
    url(../img/bg-desarrollo-ux.png) bottom right no-repeat,
    url(../img/text-ux.svg) center left no-repeat;
  background-size: 30%, 100%, auto;
}

#desarrollo-mapa ul {
  overflow-y: scroll;
}

#desarrollo-mapa ::-webkit-scrollbar {
  display: none;
}

#desarrollo-mapa span {
  top: 50%;
  width: 3500px;
  height: 150px;
  position: absolute;
  border-radius: 1000px;
  background: var(--yellow);
  transform: translate(0, -50%);
}

#desarrollo-mapa .slider {
  width: 3500px;
  padding: 0 100px;

  position: relative;
  user-select: none;
}

#desarrollo-mapa ul {
  cursor: url(../img/cursor/drag.svg), grab !important;
}

#desarrollo-mapa ul:active {
  cursor: url(../img/cursor/dragging.svg), grabbing !important;
}

#desarrollo-mapa img {
  user-select: none;
}

#desarrollo-mapa .box {
  width: 280px;
  height: 360px;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  background: #eef2ef;
  box-shadow: inset 0px 0px 0px 15px white;
}

#desarrollo-mapa .box h3 {
  font-size: 35px;
  margin-bottom: 0;
  z-index: 2;
}

#desarrollo-mapa .box h4 {
  top: 50%;
  opacity: 0;
  width: 100%;
  padding: 0 50px;
  font-size: 1.4rem;
  position: absolute;
  transform: translate(0, -50%);
  transition: all 0.5s ease-in-out;
}

#desarrollo-mapa .box .img-front {
  z-index: 1;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

#desarrollo-mapa .box .img-number {
  left: 0;
  top: 50%;
  position: absolute;
  transform: translate(0, -50%);
  transition: all 0.5s ease-in-out;
}

.item-mobile {
  height: 100vh;
}

.dot-slider-mobile {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}

.slick-active .dot-slider-mobile {
  background: var(--yellow) !important;
}

div.bg-ligth .slick-slide {
  height: auto !important;
}


.detail-proyect-dots {
  display: flex;
  justify-content: center;
  padding-left: 0;
  position: absolute;
  bottom: -5%;
  width: 100%;
}

.detail-proyect-dots .slick-active .proyect-dot-slider-mobile {
  background: #ffffff !important;
}

.detail-proyect-dots .proyect-dot-slider-mobile {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d678;
  display: inline-block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}

/* .detail-proyect-dots li{
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
} */

.carousel-branding-dots {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  position: absolute;
  align-items: center;
  top: -27px;
  width: 100%;
}

.carousel-branding-dots .slick-active .dot-slider-mobile {
  background: #ffffff !important;
  width: 15px;
  height: 5px;

}

.carousel-branding-dots .dot-slider-mobile {
  width: 12px;
  height: 3px;
  margin: 5px 7px;
  background: #d6d6d678;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}

.ul-tipografia {
  padding-left: 0 !important;
  margin-bottom: 0 !important;
}

.mobile-tap-slider {
  background: none;
  border: none;
  color: white;
}

.mobile-tabs-list {
  white-space: nowrap;
  display: flex;
  gap: 2em;
  padding-left: 0;
}

/* Dettale Proyectos*/

.texto-fotografias {
  width: 263px;
  height: 152px;
}

/* Page Branding */

header.branding {
  height: 100vh;
  background: url(../img/bg-header-desarrollo-web.jpg) center center no-repeat;
  background-size: cover;
  overflow: hidden;
}

.mt--5 {
  margin-top: -5px;
}

.mt135 {
  margin-top: 135px;
}

.mb75 {
  margin-bottom: 75px;
}

.mh60 {
  min-height: 60vh;
}

.trans-scale {
  transform: scaleX(-1);
}

.m-branding {
  margin-top: 50px;
  margin-bottom: 100px;
}

.z-index2 {
  z-index: 2;
}

.bottom30 {
  bottom: 30px;
}

/*SLIDER*/
/* Our wrapper */
.wrapperslider {
  width: 1900px;
  height: 600px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

/* Our image information */
.before,
.after {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
}

.content-image {
  height: 100%;
}

.after {
  width: 125px;
}

.scroller {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 100px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: transparent;
  opacity: 0.9;
  pointer-events: auto;
  cursor: pointer;
}

.scroller:hover {
  opacity: 1;
}

.scrolling {
  pointer-events: none;
  opacity: 1;
  /* // z-index: 1; */
}

.scroller__thumb {
  width: 100%;
  height: 100%;
  padding: 5px;
}

.scroller:before,
.scroller:after {
  content: " ";
  display: block;
  width: 7px;
  height: 9999px;
  position: absolute;
  left: 50%;
  margin-left: -3.5px;
  z-index: 30;
  transition: 0.1s;
}

.scroller:before {
  top: 100%;
}

.scroller:after {
  bottom: 100%;
}

/* If you want to cahnge the colors, make sure you change the fill in the svgs to match */
.scroller {
  border: 5px solid #fddb00;
}

.scroller:before,
.scroller:after {
  background: #fddb00;
}

.gradient:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 80%;
  background: linear-gradient(to bottom,
      transparent 50%,
      rgba(0, 33, 75, 0) 0%,
      rgba(1, 14, 29, 0) 10.299999999999999%,
      #00214b 100%);
}

/* Clases dots carousel branding*/
/* #img-carousel-branding {
  height: 88vh !important;
} */

.image-overlays {
  position: relative;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #000;
}

/* Cohete FORM css */

.cohete-form-container {
  overflow: hidden;
  height: auto;
}

.slider_projects {
  position: relative;
  background: url(../img/bg-form.jpg) center center no-repeat;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  user-select: none;
  scroll-behavior: smooth;
}

.bg-lb {
  background: #36a9e180;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@keyframes moviendrew {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translatex(7px);
  }
}

.arrowActive:hover .arrowTransition {
  animation-name: moviendrew;
  animation-duration: 0.8s;
  /* animation-iteration-count: infinite; */
  animation-direction: alternate;
  font-size: 100px;
  animation-fill-mode: forwards;
}

.arrowVolver {
  transform: rotate(180deg);
}

.arrowVolverActive {
  position: absolute;
  top: 10%;
  left: 40px;
  z-index: 10;
}

.arrowActive:hover {
  color: #fddb00 !important;
  border-bottom-color: #fddb00 !important;
}

.arrowActive:hover path {
  fill: #fddb00 !important;
}

.item-projects {
  min-width: 730px;
  width: 730px;
  height: auto;
  -webkit-transition: width all 1s ease-in-out;
  -moz-transition: width all 1s ease-in-out;
  -o-transition: width all 1s ease-in-out;
  transition: width all 1s ease-in-out;
}

#form #Icon_feather-globe path {
  fill: #e8f0fb !important;
}

.image {
  position: relative;
  width: 100%;
}

.image img {
  width: 100%;
  background: #fff;
}

.fsr-5 {
  font-size: 5rem;
}

.title-image {
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: end;
  flex-direction: column;
  padding: 0 3rem;
}

.title-image .title {
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 1;
  letter-spacing: 1.3px;
}

.title-image .link {
  color: var(--yellow);
}

-projects .title {
  font-weight: bold;
}

.link:hover {
  color: var(--yellow);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00204beb;
  z-index: 0;
  transition: opacity 0.1s,
    transform 0.8s 0.1s cubic-bezier(0.79, -0.01, 0, 0.99);
}

#jungleMobile {
  top: 0;

  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  filter: opacity(0.5);

}


#container-slider {
  position: relative;
  z-index: 2;

  cursor: url(../img/cursor/drag.svg), grab !important;
}

#container-slider:active {
  cursor: url(../img/cursor/dragging.svg), grabbing !important;
}

.autoComplete_wrapper {
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .arrowVolverActive {
    font-size: 3vw;
  }

  .arrowVolver {
    width: 18px;
  }

  #jungleMobile svg {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    background-attachment: fixed;
  }

  .to-top {
    width: 60px;
    top: -35px;
    transform: translateX(210px) !important;
  }

  #banner-desarrollo {
    padding: 100px 0 200px 0;
    background-position: left top;
  }

  #owl-exito-mobile .owl-dots {
    bottom: -35px !important;
  }

  #banner-desarrollo .img {
    right: -35%;
  }

  #desarrollo-contacto {
    padding: 50px 0;
  }

  header.desarrollo-web {
    background: url(../img/bg-header-desarrollo-web.jpg) center center no-repeat;
    background-size: cover;
  }

  ul.h1.fs-60.branding {
    font-size: 40px;
  }

  #desarrollo-mapa h3 {
    font-size: 28px !important;
    z-index: 3;
  }

  #desarrollo-mapa ul {
    padding: 0;
  }

  #desarrollo-mapa .mobile-text {
    bottom: -81px;
  }

  #desarrollo-mapa .slider {
    width: 2300px;
    padding: 80px 20px 120px;
  }

  #desarrollo-mapa .slider span {
    width: 2300px;
  }

  #desarrollo-mapa .box {
    width: 250px;
    height: 350px;
    overflow: visible;
  }
}

@media (hover: hover) {
  #desarrollo-mapa .box:hover .img-front {
    opacity: 0;
  }

  #desarrollo-mapa .box:hover .img-number {
    transform: translate(-50%, -50%);
  }

  #desarrollo-mapa .box:hover h4 {
    opacity: 1;
  }
}

@media (max-width: 1400px) {
  .containerParagraphs {
    position: relative;
  }

  .containerParagraphs::before {
    content: "";
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
    left: 0;
    height: 40px;
    background: linear-gradient(to top, var(--darkBlue), transparent);
  }

  .slideParagraphs {
    max-height: 205px;
    position: relative;
    overflow-y: scroll;
  }

  .slideParagraphs::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .slideParagraphs::-webkit-scrollbar-track {
    background: var(--darkBlue);
    opacity: 0.3s;
    width: 7px;
  }

  .slideParagraphs::-webkit-scrollbar-thumb {
    background: var(--yellow);
    border-radius: 10px;
    width: 7px;
  }
}

@media (min-width: 1440px) {
  .ff-circularBlack {
    font-size: 3.7vw;
  }
}

@media only screen and (min-width:750px) and (max-width : 1024px) {
  #slider-desarrollo .item .img {
    width: 133%;
    height: auto;
  }

  #desarrollo-mapa .slider {
    width: 3500px;
    padding: 83px 100px;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
  }

  #desarrollo-mapa .box h5 {
    bottom: -100px;
  }

  #desarrollo-mapa .box {
    overflow: visible !important;
    margin-bottom: 70px;
  }

}

@media (max-width: 850px) {
  .paleta-titulo {
    height: 26vh;
  }

  .seccion-paleta-color {
    height: 18vh;
  }

  .titulo-seccion-fuente {
    font-size: 10vw;
  }
}


@media (max-width: 650px) {
  .to-top {
    transform: translateX(190px) !important;
  }

  .cookie-disclaimer {
    width: 400px;
  }

  .marginMonito {
    margin-left: 19% !important;
  }

  .detalle-proyecto-titulo {
    font-size: 8vw;
  }

  .detalle-proyecto-subtitulo {
    font-size: 5vw;
  }
}

@media (max-width: 530px) {
  .to-top {
    width: 55px;
    top: -35px;
    transform: translateX(135px) !important;
  }

  .marginMonito {
    margin-left: 16% !important;
  }

  .detalle-proyecto-titulo {
    font-size: 8vw;
  }

  .detalle-proyecto-subtitulo {
    font-size: 5vw;
  }

  .titulo-seccion-fuente {
    font-size: 12vw;
  }
}

@media (max-width: 450px) {
  .to-top {
    width: 50px;
    top: -30px;
    transform: translateX(100px) !important;
  }

  .marginMonito {
    margin-left: 14% !important;
  }

  .detalle-proyecto-titulo {
    font-size: 10vw;
  }

  .detalle-proyecto-subtitulo {
    font-size: 6vw;
  }

  .carousel-branding-dots {
    display: flex;
    justify-content: center;
    padding-left: 0;
    position: absolute;
    width: 100%;
  }

  .titulo-seccion-fuente {
    font-size: 14vw;
  }
}

@media (max-width: 400px) {
  .to-top {
    width: 50px;
    top: -30px;
    transform: translateX(70px) !important;
  }

  .cookie-disclaimer {
    width: 300px !important;
  }

  .fs-60.branding {
    font-size: 36px !important;
  }

  .marginMonito {
    margin-left: 5% !important;
  }

  .detalle-proyecto-titulo {
    font-size: 12vw;
  }

  .detalle-proyecto-subtitulo {
    font-size: 8vw;
  }

  .carousel-branding-dots {
    display: flex;
    justify-content: center;
    padding-left: 0;
    position: absolute;
    /* top: -9%!important; */
    width: 100%;
  }
}