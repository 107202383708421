.btn-custom {
    width: 85%;
    margin-bottom: 18px;
    background-color: var(--lightBlue);
    padding: 10px 10px;
    color: #fff;
    border-radius: 20px;
    border: 1px solid var(--lightBlue);
    font-family: var(--circularBold);
    font-size: 20px;
  }

  .mobile-second-title{
    margin-top: 10px;
  }

.horizontal-galery-cont{
    background-color: var(--dblue-color);
    margin: 40px 0px;
    width: 100%;
    padding-top: 99px;
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: baseline;
  }

  .horizontal-galery-cont::-webkit-scrollbar {
    height: 4px;
  }
  
  /* Track */
  .horizontal-galery-cont::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  .horizontal-galery-cont::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 20px;
  }
  
 
  .mobile-galery-card{
    margin-right: 20px;
    margin-bottom: 20px;
    min-width: 237px;
    border-radius: 10px;
    min-width: 237px;
    min-height: 350px;
  }

  .mobile-galery-img{
    width: 100%;
    height: 350px;
    min-width: 237px;
    min-height: 350px;
    max-width: 237px;
    max-height: 350px;
    border-radius: 10px;
    object-fit: cover;
    object-position: 95% ;
  }

  .mobile-galey-card-info{
    margin-top: 20px;
  }

  .see-more-card{
    background: url(../../../../public/images/cursor.svg) right 20% center no-repeat, url(../../../../public/images/portfolioChimpa/see_more.png) ;
    background-size: 30px, cover;
    min-width: 237px;
    min-width: 237px;
    min-height: 350px;
    align-self: self-start;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .see-more-text{
    text-align: center;
    width: 80px;
  }

  .proyect-title-mobile{
    padding: 0;
    margin: 3px 0px ;
    font-size: 16px;
  }

  .card-title-mobile{
    padding: 0;
    margin-top: 0;
    font-size: 16px;
  }

  .link-card-mobile{
    padding: 0;
    margin-top: 10px;
    font-size: 16px;
  }

  .icon-link{
    font-size: 10px;
  }

  .mobile-absolute{
    position: absolute;
    width: 100%;
    left: 0;
    padding-left: 1.5rem!important;
    padding-right: 1.5rem!important;
  }