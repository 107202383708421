.article_indice {
    border-radius: 10px;
    color: #00214b;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: fit-content;
    padding: 30px 15% 20px 70px;
    margin: 2.5rem 0;
}

.articele_body p {
    min-height: 1.2rem;
}

.article_indice_contenedor_titulo {
    display: flex;
    gap: 20px;
    align-items: center;
    font-weight: bold;
    font-size: 1.9rem;
    margin-left: -15px;
}

.article_indice_contenedor_icono {
    rotate: 135deg;
    font-size: 35px;
    color: #ffda3a;
}

.ArticlePage .article_body ul.article_indice_contenedor_lista{
    list-style: none;
    list-style-type: none;
}

.article_indice_contenedor_lista {
    padding: 0;
    font-size: 1.6rem;
    text-indent: -15px;
}

.article_indice_contenedor_lista_item {
    cursor: url(../../../../assets/img/cursor-hover.svg), pointer !important;
    transition: all 0.2s ease-in-out;
    font-weight: 400;
}

.article_indice_contenedor_lista_item:hover {
    font-weight: 600;
}

@media (max-width:768px) {
    .article_indice {
        padding: 20px 10% 15px 40px;
    }

    .article_indice_contenedor_titulo {
        font-size: 1.5rem;
        gap: 15px;
    }

    .article_indice_contenedor_lista_item {
        font-size: 1.2rem;
    }

}