
.article__formulario {
    display: flex;
    flex-direction: column;
}

.article__formulario__titulo {
    font-size: 24px;
    font-weight: 600;
    margin-top: 85px;
}

.article__formulario__info {
    font-size: 18px;
}

.article__formulario__label {
    margin-top: 35px;
    font-weight: 600;
}

.article__formulario__content {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    max-width: 865px;
    border: none;
    resize: none;
    min-height: 175px;
    padding: 20px;

}

.article__formulario__autor {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    max-width: 865px;
    border: none;
    padding: 20px;

}

.article__formulario__autorEmail {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    max-width: 865px;
    border: none;
    padding: 20px;

}

.article__formulario__button__valid {
    transition: all 0.2s ease-in-out;
    display: flex;
    gap: 10px;
    background-color: #ffda3a;
    border-radius: 35px;
    border: none;
    max-width: 865px;
    font-size: 18px;
    height: 60px;
    align-items: center;
    margin: 30px 0px 150px 0px;
    justify-content: center;
    cursor: url(../../../../assets/img/cursor-hover.svg), pointer !important;
}


.article__formulario__button__valid:hover {
    background: #005081;
    color: #ffffff;
}


.article__formulario__button__invalid {
    display: flex;
    gap: 10px;
    background-color: #ffda3a;
    border-radius: 35px;
    border: none;
    max-width: 865px;
    font-size: 18px;
    height: 60px;
    align-items: center;
    margin: 30px 0px 150px 0px;
    justify-content: center;
    cursor: url(../../../../assets/img/cursor.svg), pointer !important;
}