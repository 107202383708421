.navbarBlog {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: flex-end;
  overflow-x: hidden;
  transition: all 0.5s ease-in-out;
  padding: 0;
}

.navbarBlog--collapsed {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  justify-content: flex-end;
  overflow-x: hidden;
  transition: all 0.5s ease-in-out;
}

.navbarBlog__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: auto;
  justify-content: center;
  width: -webkit-fill-available;
  margin-top: 30vh;
  transition: all 0.5s ease-in-out;
}

.navbarBlog__form--collapsed {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: auto;
  justify-content: center;
  width: -webkit-fill-available;
  margin-top: 100px;
  transition: all 0.5s ease-in-out;
}

.background {
  position: absolute;
  width: 100vw;
  background-color: #00214B;
  z-index: -1;
}

.form__titulo {
  font-size: 2.5rem;
  padding-bottom: 60px;
  font-weight: 400;
  transition: all 0.5s ease-in-out;
}

.form__titulo--collapsed {
  display: none;
  font-size: 2.5rem;
  padding-bottom: 60px;
  font-weight: 400;
  transition: all 0.5s ease-in-out;
}

.form__contenedor {
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
  max-width: 750px;
  background: hsla(0, 0%, 100%, 0.8);
  border-radius: 25px;
  margin: 0 5%;
}

.form__contenedorDeIcono {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  padding: 0 1% 0 5%;
  border-radius: 25px 0 0 25px;
}

.form__contenedorDeIcono__icono {
  color: #00214B;
  font-size: 1.5rem;
  transform: scale(-1, 1)
}

.form__contenedor__entradaDeTexto {
  height: 50px;
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  width: 100%;
}

.form__contenedor__entradaDeTexto:focus-visible {
  outline: none;
}


.form__contenedor__boton {
  font-size: 1.5rem;
  background-color: #00214B;
  color: white;
  border-radius: 25px;
  padding: 0 7%;
  border: none;
  cursor: url(../../../assets/img/cursor-hover.svg), pointer !important;
  transition: all 0.2s ease-in-out;
}
.form__contenedor__boton:hover {
  background-color:#005081;

} 

.form__contenedor__boton--collapsed {
  font-size: 1.5rem;
  background-color: #00214B;
  color: white;
  border-radius: 25px;
  padding: 0 7%;
  border: hsla(0, 0%, 100%, 0.8) 2px solid;
  cursor: url(../../../assets/img/cursor-hover.svg), pointer !important;
  transition: all 0.2s ease-in-out;
}

.form__contenedor__boton--collapsed:hover {
  background-color:#005081;

} 


.navbarBlog__fondo {
  height: 0px;
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-start;
}

.fondo__contenedor0 {
  height: 100vh;
  position: absolute;
  width: -webkit-fill-available;
  background-color: #36a9e1;
  z-index: -5;
  min-height: 388px;
}

.fondo__contenedor1 {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: -1;
}

.fondo__selva1 {
  width: 100vw;
  position: relative;
  z-index: -1;
}

.fondo__contenedor2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: -1;
}

.fondo__selva2 {
  width: 100vw;
  position: relative;
  z-index: -1;
}

.navbarBlog__contenedor {
  width: 100vw;
  padding: 10px 5% 138px 5%;
  background-color: #00214B;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}

.navbarBlog__contenedor--collapsed {
  width: 100vw;
  padding: 10px 5% 50px 5%;
  background-color: #00214B;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}


.navbarBlog__categorias {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: -webkit-fill-available;
  max-width: 1450px;
  margin: 0;
  padding: 0;
}

.categorias__categoria {
  position: relative;
  border: 3px solid rgba(255, 255, 255, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  height: 50px;
  min-width: 50px;
  margin: 2px;
  padding: 0 10px;
  border-radius: 25px;
  font-size: 1.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.3s ease-in-out;
  cursor: url(../../../assets/img/cursor-hover.svg), pointer !important;
}

.categorias__categoria2{

    position: relative;
    border: 3px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    height: 50px;
    min-width: 50px;
    margin: 2px;
    padding: 0 10px;
    border-radius: 25px;
    font-size: 1.5rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s ease-in-out;
    cursor: url(../../../assets/img/cursor-hover.svg), pointer !important;
  
}

.categorias__categoria__seleccionado {
  position: relative;
  border: 3px solid #ffda3a;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  height: 50px;
  min-width: 50px;
  margin: 2px;
  padding: 0 10px;
  border-radius: 25px;
  font-size: 1.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.3s ease-in-out;
  cursor: url(../../../assets/img/cursor-hover.svg), pointer !important;
}

.categorias__categoria__seleccionado svg {
  color: rgba(255, 255, 255, 0.3);
}


.categorias__categoria__seleccionado__Icono {
  transition: all 0.3s ease-in-out;
  color: transparent;
  margin: 5px 0px 5px 5px;

}


.categorias__categoria__seleccionado2 {
  border: 3px solid transparent;
  color: #00a3da;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  min-width: 50px;
  margin: 2px;
  padding: 0 10px;
  border-radius: 25px;
  font-size: 1.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.3s ease-in-out;

}



@media (max-width:768px) {

  .form__titulo {
    font-size: 2rem;
    padding-bottom: 30px;

  }

  .form__contenedorDeIcono {
    height: 35px;
  }

  .form__contenedorDeIcono__icono {
    font-size: 1rem;
  }

  .form__contenedor__entradaDeTexto {
    font-size: 1rem;
    height: 35px;
  }

  .form__contenedor__boton {
    height: 35px;
    font-size: 1rem;
  }

  .form__contenedor__boton--collapsed {
    height: 35px;
    font-size: 1rem;}


  .categorias__categoria__seleccionado {
    font-size: 1rem;
    border-width: 2px;
    height: 40px;
  }

  .categorias__categoria__seleccionado2 {
    font-size: 1rem;
    height: 40px;
  }

  .categorias__categoria {
    font-size: 1rem;
    border-width: 2px;
    height: 40px;
  }
  .categorias__categoria2 {
    font-size: 1rem;
    height: 40px;
  }

  }