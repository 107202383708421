.dots ul{
    margin: 0;
    
    display:flex
}
.dots ul li{
    box-sizing: border-box;
    min-height: 20px;
    min-width: 20px;
    border-radius: 20px;
    background: var(--yellow);
    margin: 0 8px;
    transition: .4s ease-in-out all;
}

.dots ul li:hover{
    opacity: .8;
}

.dots ul .active{
    min-width: 50px !important;
    border-radius: 20px !important;
}


.success_text{
    font-size: clamp(3.5rem, 3.8vw, 5rem) !important;
}

.prime-fluid{
    padding: 0 !important;
}

.item-projects{
    padding-left: 0px !important;
}