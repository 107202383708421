.style1{
    height:83%;width:77%;left: 50%;top: 50%;transform: translate(-49.7%, -50%);
}

.style2{
    height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;
}

.style3{
    filter:blur(5px);height:100%;object-fit:contain;width:100%;
}

.style4{
    height:100%;position:relative;width:100%
}

.style5{
    height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;
}

.style6{
    z-index:0;filter:blur(5px);height:100%;object-fit:contain;width:100%;
}
.imagenJungla{
    opacity: 1;
    bottom: -1px !important;
    transition: .4s ease-in-out;
}